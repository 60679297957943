import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      position: "relative",
    },
    root: {
      padding: "20px 10px 0",
      height: "calc(100vh - 258px)",
      overflowY: "auto",
      flexDirection: "column",
      display: "flex",
    },
    answerPadding: {
      padding: "1px 0 0 5px",
    },
    red: {
      color: theme.palette.error.main,
    },
    green: {
      color: theme.palette.success.main,
    },
    answer: {
      width: "100%",
      display: "inline-flex",
      justifyContent: "flex-start",
    },
    test: {
      "& .MuiButtonBase-root": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  })
);

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.info.main,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    fill: theme.palette.info.main,
  },
  "& .MuiTypography-root": {
    fontWeight: 600,
  },
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.info.main,
  "& .MuiTypography-root": {
    fontWeight: 500,
  },
}));

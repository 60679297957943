import { makeStyles, createStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      position: "relative",
    },
    root: {
      padding: "0 10px",
      height: "calc(100vh - 238px)",
      overflowY: "auto",
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
    },
    image: {
      display: "flex",
      height: 600,
      paddingTop: 16,
      paddingLeft: "3%",
      justifyContent: "center",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      alignItems: "center",
    },
  })
);

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.info.main,
  height: 8,
  "@media (min-width: 500px)": {
    width: 380,
  },
  "@media (min-width: 550px)": {
    width: 420,
  },
  "@media (min-width: 650px)": {
    width: 510,
  },
  "@media (min-width: 750px)": {
    width: 600,
  },
  "@media (min-width: 900px)": {
    width: 440,
  },
  "@media (min-width: 1000px)": {
    width: 480,
  },
  "@media (min-width: 1100px)": {
    width: 530,
  },
  "@media (min-width: 1200px)": {
    width: 600,
  },
  "@media (min-width: 1400px)": {
    width: 640,
  },
  "@media (min-width: 1500px)": {
    width: 730,
  },
  "@media (min-width: 1600px)": {
    width: 780,
  },
  "@media (min-width: 1700px)": {
    width: 830,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: `3px solid ${theme.palette.info.main}`,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-mark": {
    backgroundColor: theme.palette.info.main,
    height: 30,
    width: 2,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: theme.palette.info.main,
  },
}));

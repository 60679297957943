import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import image from "images/selectWay1.jpeg";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
      height: "calc(100vh - 160px)",
      padding: "0 10px",
      backgroundColor: theme.palette.secondary.main,
      alignContent: "flex-start",
      pandingTop: 20,
    },
    main: {
      height: "100%",
      alignContent: "center",
      justifyContent: "center",
    },
    back: {
      height: "calc(100vh - 80px)",
      background: `url(${image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      mixBlendMode: "normal",
      backgroundColor: theme.palette.secondary.main,
    },
    info: {
      padding: 20,
      "&.MuiPaper-root": {
        backgroundColor: "rgba(255, 255, 255, .8)",
      },
    },
    resultRow: {
      width: "100%",
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    answer: {
      color: theme.palette.info.main,
      textAlign: "right",
      "&.MuiTypography-root": {
        fontWeight: 500,
      },
    },
    withoutGaz: {
      paddingTop: 10,
      color: theme.palette.error.main,
      textAlign: "center",
      "&.MuiTypography-root": {
        fontWeight: 500,
      },
    },
    bold: {
      "&.MuiTypography-root": {
        fontWeight: 500,
      },
    },
    paddingBottom: {
      paddingBottom: 10,
    },
  })
);

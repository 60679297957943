import React from "react";
import { Link, Grow, IconButton } from "@mui/material";
import { HelpCenter, LocalGroceryStore } from "@mui/icons-material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useStyles } from "components/common/KnowledgeBase/KnowledgeBaseStyle";

interface Props {
  knowledgeBaseLink: string;
  title: string;
  isShop?: boolean;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

const KnowledgeBase: React.FC<Props> = ({
  knowledgeBaseLink,
  title,
  isShop,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Link href={knowledgeBaseLink} target="_blank">
      <CustomTooltip
        title={`${title} ${knowledgeBaseLink}`}
        TransitionComponent={Grow}
        arrow
        placement="left"
        TransitionProps={{ timeout: 600 }}
      >
        <IconButton className={classes.button} size="large">
          {isShop ? (
            <LocalGroceryStore color="info" fontSize="large" />
          ) : (
            <HelpCenter color="info" fontSize="large" />
          )}
        </IconButton>
      </CustomTooltip>
    </Link>
  );
};

export default KnowledgeBase;

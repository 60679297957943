import React from "react";
import { Divider, Chip, Typography } from "@mui/material";
import { AnswerResult } from "types";
import { useStyles } from "components/common/TechnologyResultItem/TechnologyResultItemStyle";

interface Props {
  answers: AnswerResult[];
  description?: string;
}

const TechnologyResultItem: React.FC<Props> = ({
  answers,
  description,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      {description ? (
        <Divider className={classes.divider}>
          <Chip className={classes.title} label={description} />
        </Divider>
      ) : (
        <Divider className={classes.dividerMain} />
      )}

      {answers.map((item: AnswerResult) => {
        return (
          <div key={item.stepId} className={classes.resultRow}>
            <Typography component="span">{item.question}</Typography>
            <Typography className={classes.answer} component="span">
              {item.answer}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TechnologyResultItem;

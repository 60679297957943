import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      height: "calc(100vh - 238px)",
      alignContent: "flex-start",
    },
    slider: {
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      height: 200,
      backgroundColor: theme.palette.secondary.main,
    },
    center: {
      "&.MuiGrid-root": {
        display: "flex",
        justifyContent: "center",
      },
    },
    imagesContent: {
      height: 250,
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiCard-root": {
        margin: "auto 50px",
        height: "calc(100vh - 236px)",
        display: "flex",
        flexDirection: "column",
        maxHeight: 750,
      },
    },
    header: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.secondary.main,
    },
    image: {
      "&.MuiCardMedia-root": {
        height: "calc(100vh - 435px)",
      },
    },
    content: {
      height: "100%",
      backgroundColor: theme.palette.info.main,
      color: theme.palette.secondary.main,
      "&.MuiCardContent-root": {
        padding: "5px 10px 0 10px",
      },
    },
    price: {
      color: "#fff",
      textAlign: "right",
      "&.MuiTypography-root": {
        fontWeight: 500,
      },
    },
    footer: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.info.main,
      "&.MuiCardActions-root": {
        padding: "0 8px 8px 8px",
      },
    },
    iconBox: {
      width: "40%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    button: {
      "&.MuiButtonBase-root": {
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: theme.palette.error.main,
        },
        "&:hover .MuiSvgIcon-root": { color: "#fff" },
      },
    },
  })
);

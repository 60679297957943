import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import KnowedgeBase from "components/common/KnowledgeBase/KnowledgeBase";
import { CustomStepProps, SliderMarks, MarksId, MarkSelection } from "types";
import {
  useStyles,
  CustomSlider,
} from "components/features/MaterialThickness/MaterialThicknessStyle";

type Result = {
  userSelection: MarkSelection;
};

const emptyResult: Result = {
  userSelection: {
    value: 3,
    type: MarksId.mark1,
  },
};

const MaterialThickness: React.FC<CustomStepProps> = ({
  setResult,
  result,
  marks: genericMarks,
  type: genericType,
  image: genericImage,
  knowledgeBaseLink: rawKnowledgeBaseLink,
  knowledgeBaseTitle: rawKnowledgeBaseTitle,
}): JSX.Element => {
  const classes = useStyles();
  const marks = genericMarks as SliderMarks[];
  const type = genericType as MarksId;
  const image = genericImage as string;
  const knowledgeBaseLink = rawKnowledgeBaseLink as string;
  const knowledgeBaseTitle = rawKnowledgeBaseTitle as string;

  useEffect(() => {
    if (result === undefined) {
      setResult({
        userSelection: { ...emptyResult.userSelection, type },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (event: Event, newValue: number | number[]) => {
    const result: Result = {
      userSelection: { type, value: newValue },
    };
    setResult(result);
  };

  const { userSelection } = (result as Result) || emptyResult;

  const valueText = (value: number) => {
    const resultMark = marks.find((item: SliderMarks) => item.value === value);
    if (resultMark) {
      return resultMark.thickness;
    }
  };

  return (
    <Grid justifyContent="center" display="flex">
      <Grid item xs={12} sm={12} md={10} className={classes.main}>
        <div className={classes.root}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url(${image})` }}
          >
            <CustomSlider
              step={1}
              marks={marks}
              value={userSelection.value}
              valueLabelFormat={valueText}
              onChange={onChange}
              min={1}
              max={marks.length}
              valueLabelDisplay="auto"
              name="thickness"
            />
          </div>
        </div>
        {knowledgeBaseLink && (
          <KnowedgeBase
            title={knowledgeBaseTitle}
            knowledgeBaseLink={knowledgeBaseLink}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MaterialThickness;

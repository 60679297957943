import React from "react";
import { Paper, Grow, ButtonBase } from "@mui/material";
import { HelpCenter } from "@mui/icons-material";
import classNames from "classnames";
import {
  useStyles,
  CustomTooltip,
} from "components/common/CustomRadioButton/CustomRadioButtonStyle";

interface Props {
  id: string;
  image: string;
  help: string;
  selectedItem: string | null;
  onClick: (id: string) => void;
}

const CustomRadioButton: React.FC<Props> = ({
  id,
  image,
  help,
  selectedItem,
  onClick,
}): JSX.Element => {
  const classes = useStyles();

  const imageBackdropClasses = classNames({
    [classes.imageBackdrop]: true,
    [classes.selected]: selectedItem === id,
    [classes.unselected]: selectedItem !== id,
  });

  return (
    <Paper elevation={selectedItem === id ? 1 : 24} className={classes.root}>
      <div className={classes.infoBox}>
        <CustomTooltip
          title={help}
          TransitionComponent={Grow}
          arrow
          placement="bottom-end"
          TransitionProps={{ timeout: 600 }}
        >
          <HelpCenter fontSize="large" className={classes.helpIcon} />
        </CustomTooltip>
      </div>
      <ButtonBase
        focusRipple
        onClick={() => onClick(id)}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <span className={imageBackdropClasses} />
      </ButtonBase>
    </Paper>
  );
};

export default CustomRadioButton;

import React, { useState } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCurrentStep, setResultToCurrentStep } from "redux/stepSlice";
import CustomDirectButton from "components/common/CustomDirectButton/CustomDirectButton";
import { useStyles } from "components/features/StepEditPlatform/StepEditPlatformStyle";
import { ListsId } from "types";
import { mySteps } from "entryData";

const StepEditPlatform: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentStepId = useAppSelector((state) => state.step.currentStep);
  const currentStep = mySteps[currentStepId];
  const stepResult = useAppSelector(
    (state) => state.step.stepResults[currentStepId]
  );
  const earielResult = useAppSelector(
    (state) => state.step.stepResults[currentStepId - 5]
  );
  const [previousSteps, setPreviousSteps] = useState<number[]>([]);

  const setResult = (result: any) => {
    dispatch(setResultToCurrentStep(result));
  };

  const goBack = () => {
    if (previousSteps.length > 0) {
      const copyPreviousStep = [...previousSteps];
      let removedStep = copyPreviousStep.pop();
      if (
        earielResult &&
        (earielResult.userSelection === `${ListsId.list3}/1` ||
          earielResult.userSelection === `${ListsId.list3}/4`)
      ) {
        removedStep = copyPreviousStep.pop();
      }
      setPreviousSteps(copyPreviousStep);

      if (typeof removedStep === "number") {
        dispatch(setCurrentStep(removedStep));
      }
    }
  };

  const goNext = (stepId: number) => {
    setPreviousSteps([...previousSteps, currentStepId]);
    dispatch(setCurrentStep(stepId));
  };

  const onDefaultNext = () => {
    const { defaultTransition }: { defaultTransition?: number } = currentStep;
    if (defaultTransition) {
      goNext(defaultTransition);
    }
  };

  const {
    component: ContentComponent,
    mainImage,
    isOther,
    nextButton,
    otherButton,
    requiredAnswer,
  } = currentStep;

  return (
    <div>
      {!isOther && mainImage ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={3}>
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${mainImage.image})` }}
            >
              {mainImage.title && (
                <Typography
                  variant="h6"
                  textAlign="center"
                  className={classes.imageTitle}
                >
                  {mainImage.title.toUpperCase()}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <div className={classes.content}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={10} className={classes.header}>
                  <Paper
                    variant="outlined"
                    className={classes.titleBox}
                    id="title-box"
                  >
                    <Typography
                      className={classes.title}
                      variant="h6"
                      textAlign="center"
                    >
                      {currentStep.title.toUpperCase()}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <ContentComponent
                {...currentStep.componentProps}
                goNext={goNext}
                goBack={goBack}
                setResult={setResult}
                result={stepResult}
                currentStepId={currentStep.id}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <ContentComponent
          {...currentStep.componentProps}
          goNext={goNext}
          goBack={goBack}
          setResult={setResult}
          result={stepResult}
        />
      )}
      <Grid container justifyContent="center">
        <Paper className={classes.buttonsBox}>
          <Grid item xs={12} sm={12} md={6} className={classes.buttons}>
            <CustomDirectButton
              label="Wróć"
              isLeft
              onClick={goBack}
              isDisabled={previousSteps.length === 0}
            />
            {nextButton && (
              <CustomDirectButton
                label={nextButton}
                isRight
                onClick={onDefaultNext}
                isDisabled={requiredAnswer && stepResult === undefined}
              />
            )}
            {otherButton && (
              <CustomDirectButton
                label={otherButton.label}
                onClick={() => goNext(otherButton.target)}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default StepEditPlatform;

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "&.MuiFormControl-root": {
        paddingBottom: 20,
      },
    },
    label: {
      "&.MuiFormLabel-root": {
        color: theme.palette.info.main,
      },
    },
    input: {
      "&.MuiInput-root .MuiInput-input": {
        color: theme.palette.info.main,
        fontWeight: 500,
      },
    },
  })
);

import { configureStore } from "@reduxjs/toolkit";
import stepReducer from "redux/stepSlice";
import resultsApiReducer from "redux/apiSlice";

export const store = configureStore({
  reducer: {
    step: stepReducer,
    api: resultsApiReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { AnswerResult, ApiResponseItem } from "types";
import { styles } from "components/common/CreatorPDF/CreatorPDFStyle";
import { formatPrice } from "functions";
import logo from "images/Logo.png";

interface Props {
  answers: AnswerResult[];
  results: ApiResponseItem[];
}

const CreatorPDF: React.FC<Props> = ({ answers, results }): JSX.Element => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.contact}>
            <Text style={styles.header}>
              Tomsystem Sp. z o.o. ul. Miłocińska 15A 35-232 Rzeszów
            </Text>
            <Text style={styles.header}>
              Telefon: +48 17 863 72 27 / +48 17 863 27 01
            </Text>
            <Text style={styles.header}>E-mail: tomsystem@tomsystem.pl</Text>
            <Text style={styles.header}>www: https://tomsystem.pl/</Text>
          </View>
        </View>
        <View style={styles.content}>
          <Text style={styles.textCenter}>Rezultat konfiguracji kreatora:</Text>
          {answers.map((item: AnswerResult) => {
            return (
              <Text key={item.stepId} style={styles.description}>
                {`${item.question} `}
                <Text style={styles.result}>{item.answer}</Text>
              </Text>
            );
          })}
          <Text style={styles.textCenter}>
            Lista urządzeń na podstawie konfiguracji kreatora
          </Text>
          {results.map((item: ApiResponseItem, index: number) => {
            return (
              <div style={styles.resultsBox} key={item.id}>
                <Text style={styles.item} key={item.id}>
                  {`${index + 1}. ${item.name}, cena: ${formatPrice(item.price)} PLN   `}
                  <Text style={styles.text}>{item.description}</Text>
                </Text>
                <div style={styles.imageBox}>
                  <Image
                    src={{
                      uri: item.url,
                      method: "GET",
                      headers: {},
                      body: "",
                    }}
                    style={styles.image}
                  />
                </div>
              </div>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default CreatorPDF;

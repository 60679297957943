import React from "react";
import { Grid } from "@mui/material";
import KnowledgeBase from "components/common/KnowledgeBase/KnowledgeBase";
import { CustomStepProps } from "types";
import { useStyles } from "components/features/ImageReader/ImageReaderStyle";

const ImageReader: React.FC<CustomStepProps> = ({
  image: genericImage,
  knowledgeBaseLink: rawKnowledgeBaseLink,
  knowledgeBaseTitle: rawKnowledgeBaseTitle,
}): JSX.Element => {
  const classes = useStyles();
  const image = genericImage as string;
  const knowledgeBaseLink = rawKnowledgeBaseLink as string;
  const knowledgeBaseTitle = rawKnowledgeBaseTitle as string;

  return (
    <Grid justifyContent="center" display="flex" className={classes.container}>
      <Grid item xs={12} sm={12} md={10} className={classes.main}>
        <div
          className={classes.root}
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          {knowledgeBaseLink && (
            <KnowledgeBase
              title={knowledgeBaseTitle}
              knowledgeBaseLink={knowledgeBaseLink}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ImageReader;

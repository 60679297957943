import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      overflowY: "auto",
      marginTop: "auto",
      marginBottom: "auto",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignContent: "space-around",
    },
    center: {
      justifyContent: "center",
    },
    between: {
      justifyContent: "space-between",
    },
  })
);

import React from "react";
import { Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useStyles } from "components/common/CustomDirectButton/CustomDirectButtonStyle";

interface Props {
  label: string;
  isLeft?: boolean;
  isRight?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
}

const CustomDirectButton: React.FC<Props> = (props): JSX.Element => {
  const { label, isLeft, isRight, onClick, isDisabled } = props;
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      className={classes.root}
      variant="contained"
      disabled={isDisabled}
    >
      {isLeft && <ArrowBack fontSize="small" className={classes.iconLeft} />}
      {label}
      {isRight && (
        <ArrowForward fontSize="small" className={classes.iconRight} />
      )}
    </Button>
  );
};

export default CustomDirectButton;

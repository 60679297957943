import React from "react";

export interface ChoiceListItem {
  id: string;
  title: string;
  description?: string;
  linkText?: string;
  linkUrl?: string;
  image?: string;
  checked?: boolean;
  tooltip?: boolean;
  available?: string[];
}

export interface ChoiceList {
  id: ListsId;
  name: string;
  items: ChoiceListItem[];
}

export interface StepResult {
  result?: any;
}

export type CustomStepProps = {
  result?: any;
  setResult: (result: any) => {};
  goNext: (stepId: number) => {};
  goBack: () => {};
  currentStepId: number;
  [x: string]: any;
};

export interface MainImageInfo {
  title?: string;
  image: string;
}

export interface GenericStep {
  id: number;
  title: string;
  defaultTransition?: number;
  component: React.ComponentType<CustomStepProps>;
  componentProps?: any;
  mainImage?: MainImageInfo;
  isOther?: boolean;
  nextButton?: string;
  otherButton?: OtherButtonInfo;
  requiredAnswer?: boolean;
}

export interface OtherButtonInfo {
  target: number;
  label: string;
}

export interface ModalInfoHelpItem {
  content: string;
  link?: string;
  isBold?: boolean;
}

export interface ModuleInfoHelp {
  tooltipLabel: string;
  modalTitle: string;
  items: ModalInfoHelpItem[];
}

export interface ModuleInfo {
  id?: number;
  title: string;
  image: string;
  help?: ModuleInfoHelp;
}

export interface ReaderItemInfo {
  title: string;
  description?: string;
}

export interface ReaderInfo {
  content: string;
  isBold?: boolean;
  icon?: IconType;
  items?: ReaderItemInfo[];
  link?: string;
}

export interface ImageInfo {
  content: string;
  size: string;
  position: string;
}

export interface ImageModalInfo {
  id: number;
  title: string;
  image: ImageInfo;
  items: ReaderInfo[];
}

export interface ImageBoxInfo {
  id: number;
  image: string;
  title: string;
}

export interface AccordionInfo {
  id: number;
  title: string;
  answer: boolean;
  content: string;
}

export interface SliderMarks {
  value: number;
  label?: string;
  thickness?: string;
  hours?: string;
}

export interface AnswerResult {
  stepId: number;
  question: string;
  path?: AnswerPath;
  answer?: string;
}

export interface MarkSelection {
  value: number | number[];
  type: MarksId;
}

export interface WaySelection {
  id: number;
  title: string;
}

export interface ReadStep {
  userSelection: MarkSelection | string | WaySelection;
}

export interface ApiResponseItem {
  id: number;
  name: string;
  description: string;
  url: string;
  price: string | number;
  sku: string;
}

export interface UserInfo {
  name?: string;
  email: string;
  phone: string;
}

export enum AnswerPath {
  common,
  cut,
  weld,
}

export enum ListsId {
  "list1" = "list_1",
  "list2" = "list_2",
  "list3" = "list_3",
  "list4" = "list_4",
  "list8" = "list_8",
}

export enum MarksId {
  "mark1" = "mark_1",
  "mark2" = "mark_2",
  "mark3" = "mark_3",
  "mark4" = "mark_4",
}

export enum IconType {
  "check",
  "dangerous",
  "warning",
}

export enum ErrorType {
  "error" = "error",
  "warning" = "warning",
  "info" = "info",
}

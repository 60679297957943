import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      padding: "10px 0",
    },
    dividerMain: {
      padding: "10px 0",
      "&.MuiDivider-root": {
        marginBottom: 10,
      },
    },
    title: {
      "&.MuiChip-root": {
        fontWeight: 600,
        fontSize: "1rem",
      },
    },
    resultRow: {
      width: "100%",
      display: "inline-flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    answer: {
      color: theme.palette.info.main,
      "&.MuiTypography-root": {
        fontWeight: 500,
      },
    },
  })
);

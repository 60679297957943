import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      display: "flex",
      flexDirection: "column-reverse",
      height: "calc(100vh - 160px)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    header: {
      "&.MuiGrid-root": {
        zIndex: 50,
      },
    },
    titleBox: {
      padding: "8px 16px",
      marginTop: 30,
      "&.MuiPaper-root": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "unset",
      },
    },
    title: {
      color: "#fff",
      fontWeight: 700,
    },
    imageTitle: {
      "&.MuiTypography-root": {
        padding: "10px 2px",
        backgroundColor: "rgba(255, 255, 255, .7)",
        color: theme.palette.info.main,
        borderTop: "3px solid #fff",
      },
    },
    buttonsBox: {
      backgroundColor: "#fff",
      width: "100%",
      zIndex: 20,
      display: "flex",
      justifyContent: "center",
    },
    buttons: {
      height: 80,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    content: {
      height: "calc(100vh - 160px)",
      display: "flex",
      flexDirection: "column",
      marginTop: "auto",
      marginBottom: "auto",
    },
  })
);

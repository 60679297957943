import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme, styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 210,
      height: 193,
      position: "relative",
      margin: "30px 5px",
      "@media (min-width: 1200px)": {
        margin: "30px 15px",
      },
      "@media (min-width: 1400px)": {
        margin: "30px 38px",
      },
      "@media (min-width: 1840px)": {
        margin: "30px 70px",
      },
      "@media (min-width: 2200px)": {
        margin: "30px 100px",
      },
      "&.MuiPaper-root": {
        borderRadius: 10,
      },
    },
    infoBox: {
      position: "absolute",
      top: -2,
      right: -2,
      zIndex: 99,
    },
    helpIcon: {
      paddingLeft: 10,
      color: theme.palette.info.main,
    },
    image: {
      position: "relative",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
        height: 100,
      },
    },
    focusVisible: {},
    imageButton: {
      maxHeight: "60px",
      position: "absolute",
      left: "43vw",
      right: 0,
      top: "8vh",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      borderRadius: 10,
      transition: theme.transitions.create("opacity"),
    },
    selected: {
      opacity: 0,
    },
    unselected: {
      opacity: 0.3,
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
      borderRadius: 10,
      transition: "3s",
      transform: "scale(1)",
    },
  })
);

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

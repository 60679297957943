import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

export const isEven = (value: number) => {
  return value % 2 === 0;
};

export const cutText = (content: string, maxLength: number) => {
  if (maxLength > 0) {
    if (maxLength < content?.length) {
      let cutOutText = content.substr(0, maxLength + 1);
      let checkChar = cutOutText.substring(
        cutOutText.length - 1,
        cutOutText.length
      );

      if (checkChar === " ") {
        return `${cutOutText.substring(0, cutOutText.length - 1)}...`;
      } else {
        return `${cutOutText.substr(0, cutOutText.lastIndexOf(" "))}...`;
      }
    } else {
      return content;
    }
  } else {
    return "Error";
  }
};

export const formatPrice = (price: number | string): string => {
  console.log(price);

  return Number(price).toFixed(2);
}
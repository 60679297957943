import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StepResult } from "types";
import { firstStepId } from "entryData";

export type MainState = {
  currentStep: number;
  stepResults: Record<number, any>; // StepId, Custom Step Result
};

export const initialState: MainState = {
  currentStep: firstStepId,
  stepResults: [],
};

const main = createSlice({
  name: "step",
  initialState,
  reducers: {
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setResultToCurrentStep(state, action: PayloadAction<StepResult>) {
      const currentStepId = state.currentStep;
      state.stepResults[currentStepId] = action.payload;
    },
    removeSelectedStep(state, action: PayloadAction<number>) {
      state.stepResults[action.payload] = undefined;
    },
  },
});

export const { setCurrentStep, setResultToCurrentStep, removeSelectedStep } =
  main.actions;
export default main.reducer;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Paper,
  Backdrop,
  Alert,
  Badge,
  Snackbar,
  Slide,
  Divider,
  Grow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogContentText,
} from "@mui/material";
import {
  ImageSearch,
  Send,
  PictureAsPdf,
  HelpCenter,
  EmojiObjects,
  Close,
  AttachEmail,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import classNames from "classnames";
import { usePDF, BlobProvider } from "@react-pdf/renderer";
import Carousel from "react-material-ui-carousel";
import CarouselItem from "components/common/CarouselItem/CarouselItem";
import TechnologyResultItem from "components/common/TechnologyResultItem/TechnologyResultItem";
import SendingQuestionModal from "../SendingQuestionModal/SendingQuestionModal";
import CreatorPDF from "components/common/CreatorPDF/CreatorPDF";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  apiSelector,
  addResults,
  setMessage,
  resetErrors,
  addResultFile,
} from "redux/apiSlice";
import {
  CustomStepProps,
  AnswerResult,
  AnswerPath,
  MarkSelection,
  ReadStep,
  MarksId,
  SliderMarks,
  ListsId,
  ChoiceList,
  ChoiceListItem,
  ApiResponseItem,
} from "types";
import { filteringVentilationSolutions } from "entryData";
import {
  useStyles,
  CustomTooltip,
} from "components/features/TechnologySummary/TechnologySummaryStyle";

const TechnologySummary: React.FC<CustomStepProps> = ({
  answers: genericAnswers,
  marks: genericMarks,
  lists: genericLists,
}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fullScrean = useMediaQuery(theme.breakpoints.down("md"));
  const results = useAppSelector((store) => store.step.stepResults);
  const apiResult = useAppSelector(apiSelector);
  const answers = genericAnswers as AnswerResult[];
  const marks = genericMarks as Record<MarksId, SliderMarks[]>;
  const lists = genericLists as Record<ListsId, ChoiceList>;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInfoModal, setIsInfoModal] = useState<boolean>(false);
  const [commonAnswers, setCommonAnswers] = useState<AnswerResult[]>([]);
  const [cutAnswers, setCutAnswers] = useState<AnswerResult[]>([]);
  const [weldAnswers, setWeldAnswers] = useState<AnswerResult[]>([]);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pdfInstance, updatePdfInstance] = usePDF({
    document: (
      <CreatorPDF
        answers={[...commonAnswers, ...weldAnswers, ...cutAnswers]}
        results={apiResult.results}
      />
    ),
  });

  const isCut = results[4].userSelection.id === 5;

  useEffect(() => {
    let common: AnswerResult[] = [];
    let cut: AnswerResult[] = [];
    let weld: AnswerResult[] = [];
    answers.forEach((item: AnswerResult) => {
      const step: ReadStep = results[item.stepId];
      if (step) {
        let result: string = "";
        if (typeof step.userSelection === "string") {
          const listId = step.userSelection.substring(
            0,
            step.userSelection.lastIndexOf("/")
          ) as ListsId;
          const listItem = lists[listId].items.find(
            (itemList: ChoiceListItem) => itemList.id === step.userSelection
          );
          if (listItem) {
            result = listItem.title;
          }
        } else {
          const markSelection = step.userSelection as MarkSelection;
          const markItem = marks[markSelection.type].find(
            (itemMark: SliderMarks) => itemMark.value === markSelection.value
          );
          if (markItem && markItem.thickness) {
            result = markItem.thickness;
          }
          if (markItem && markItem.hours) {
            result = markItem.hours;
          }
        }
        if (item.path === AnswerPath.common) {
          common.push({
            ...item,
            answer: result,
          });
        }
        if (item.path === AnswerPath.cut) {
          cut.push({
            ...item,
            answer: result,
          });
        }
        if (item.path === AnswerPath.weld) {
          weld.push({
            ...item,
            answer: result,
          });
        }
      }
    });
    setCommonAnswers(common);
    setCutAnswers(cut);
    setWeldAnswers(weld);
    dispatch(
      addResults({
        common,
        path: isCut ? "cut" : "weld",
        type: isCut ? cut : weld,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiResult.isSending && apiResult.message.length) {
      setIsOpen(false);
    }
  }, [apiResult.isSending, apiResult.message]);

  useEffect(() => {
    if (
      apiResult.results.length &&
      commonAnswers.length &&
      (weldAnswers || cutAnswers)
    ) {
      updatePdfInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResult.results, commonAnswers, weldAnswers, cutAnswers]);

  useEffect(() => {
    if (pdfInstance.blob !== null) {
      setPdfFile(
        new File([pdfInstance.blob], "report.pdf", {
          type: "application/pdf",
        })
      );
    }
  }, [pdfInstance]);

  const infoModalHandling = () => {
    setIsInfoModal(!isInfoModal);
  };

  const onClose = () => {
    dispatch(setMessage(""));
    dispatch(resetErrors());
  };

  const onSend = (isSend: boolean) => {
    setIsOpen(isSend);
  };

  const onSendFileToStore = () => {
    if (pdfFile !== null) {
      dispatch(addResultFile(pdfFile));
    }
  };

  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={apiResult.isPending || apiResult.isSending}
      >
        {apiResult.isSending && <CircularProgress color="secondary" />}
      </Backdrop>
      <div className={classes.back}>
        <Grid container className={classes.main}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            className={classNames(classes.content, classes.center)}
          >
            <Grid item xs={12} sm={12} md={12} className={classes.header}>
              <Paper elevation={16} className={classes.info}>
                <Typography
                  className={classes.bold}
                  textAlign="center"
                >{`Cześć ${
                  results[0] ? results[0].userName : ""
                } !`}</Typography>
                <Typography className={classes.bold} textAlign="center">
                  Cieszymy się, że mogliśmy pomóc rozwiązać chociaż jeden z
                  Twoich problemów :) W tym miejscu możemy zaproponować
                  optymalne rozwiązanie w kwestii doboru technologii spawania.
                  Nasz system na postawie udzielonych przez Ciebie odpowiedzi
                  ustalił że:
                </Typography>
                <div className={classes.badge}>
                  <Badge
                    color="error"
                    badgeContent={apiResult.results.length}
                    className={classes.badgeContent}
                  >
                    <ImageSearch color="secondary" fontSize="large" />
                  </Badge>
                </div>
                {commonAnswers.length && (
                  <TechnologyResultItem answers={commonAnswers} />
                )}
                {cutAnswers.length > 0 && isCut && (
                  <TechnologyResultItem
                    answers={cutAnswers}
                    description="Cięcie"
                  />
                )}
                {weldAnswers.length > 0 && !isCut && (
                  <TechnologyResultItem
                    answers={weldAnswers}
                    description="Spawanie"
                  />
                )}
                <Divider className={classes.divider} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 10,
                  }}
                >
                  <span className={classes.buttonContent}>
                    <CustomTooltip
                      title="Dowiedz się więcej o filtrowentylacji"
                      TransitionComponent={Grow}
                      arrow
                      placement="top"
                      TransitionProps={{ timeout: 600 }}
                    >
                      <IconButton
                        onClick={infoModalHandling}
                        className={classes.button}
                      >
                        <HelpCenter color="secondary" />
                      </IconButton>
                    </CustomTooltip>
                  </span>
                  <span className={classes.buttonContent}>
                    <CustomTooltip
                      className={classes.buttonContent}
                      title="Zapisz konfigurację"
                      TransitionComponent={Grow}
                      arrow
                      placement="top"
                      TransitionProps={{ timeout: 600 }}
                    >
                      <IconButton
                        onClick={onSendFileToStore}
                        className={classes.button}
                      >
                        <Send color="secondary" />
                      </IconButton>
                    </CustomTooltip>
                  </span>
                  <BlobProvider
                    document={
                      <CreatorPDF
                        answers={[
                          ...commonAnswers,
                          ...weldAnswers,
                          ...cutAnswers,
                        ]}
                        results={apiResult.results}
                      />
                    }
                  >
                    {({ url }) => (
                      <a
                        className={classes.buttonContent}
                        href={url !== null ? url : ""}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CustomTooltip
                          title="Otwórz raport"
                          TransitionComponent={Grow}
                          arrow
                          placement="top"
                          TransitionProps={{ timeout: 600 }}
                        >
                          <IconButton className={classes.button}>
                            <PictureAsPdf color="secondary" />
                          </IconButton>
                        </CustomTooltip>
                      </a>
                    )}
                  </BlobProvider>
                  <span className={classes.buttonContent}>
                    <CustomTooltip
                      title="Wyślij konfigurację"
                      TransitionComponent={Grow}
                      arrow
                      placement="top"
                      TransitionProps={{ timeout: 600 }}
                    >
                      <IconButton
                        onClick={() => onSend(true)}
                        className={classes.button}
                      >
                        <AttachEmail color="secondary" />
                      </IconButton>
                    </CustomTooltip>
                  </span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            className={classNames(classes.content, classes.center)}
          >
            {apiResult.isPending && (
              <div className={classes.spinerBox}>
                <CircularProgress color="secondary" />
              </div>
            )}
            {!apiResult.isPending && apiResult.results.length > 0 && (
              <Carousel
                className={classes.carousel}
                indicators={false}
                navButtonsAlwaysVisible={apiResult.results.length > 1}
                autoPlay={false}
                navButtonsProps={{
                  style: {
                    backgroundColor: "#005BA2",
                    color: "#fff",
                  },
                }}
              >
                {apiResult.results.map((item: ApiResponseItem) => {
                  return <CarouselItem key={item.id} item={item} />;
                })}
              </Carousel>
            )}
            {apiResult.error.isError && (
              <Alert variant="filled" severity={apiResult.error.type}>
                {`${apiResult.error.message} Kliknij przycisk Wróć i spróbuj jeszcze raz`}
              </Alert>
            )}
          </Grid>
          <SendingQuestionModal
            isOpen={isOpen}
            onClose={onSend}
            userName={results[0] ? results[0].userName : undefined}
            file={pdfFile}
          />
          <Snackbar
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={
              apiResult.message.length > 0 || apiResult.sendingError.isError
            }
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
          >
            <Alert
              variant="filled"
              severity={
                apiResult.sendingError.isError
                  ? apiResult.sendingError.type
                  : "info"
              }
            >
              {apiResult.sendingError.isError
                ? apiResult.sendingError.message
                : apiResult.message}
            </Alert>
          </Snackbar>
          <Dialog
            fullScreen={fullScrean}
            open={isInfoModal}
            onClose={infoModalHandling}
          >
            <DialogTitle className={classes.dialogTitle}>
              Proponowane rozwiązania w zakresie filtrowentylacji w procesie
              spawania
            </DialogTitle>
            <div className={classes.closeButton}>
              <IconButton onClick={infoModalHandling}>
                <Close />
              </IconButton>
            </div>
            <DialogContent>
              {filteringVentilationSolutions.map(
                (item: string, index: number) => {
                  return (
                    <DialogContentText
                      className={classes.dialogContent}
                      key={index}
                    >
                      <EmojiObjects
                        fontSize="large"
                        color="warning"
                        className={classes.icon}
                      />
                      {item}
                    </DialogContentText>
                  );
                }
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </div>
    </div>
  );
};

export default TechnologySummary;

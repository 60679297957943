import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: "hidden",
      paddingTop: "16px",
      marginTop: "auto",
      marginBottom: "auto",
    },
    main: {
      position: "relative",
      overflowY: "auto",
    },
    root: {
      overflowY: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    form: {
      "&.MuiFormControl-root": {
        height: "fit-content",
      },
    },
    item: {
      marginBottom: 20,
    },
    title: {
      "&.MuiTypography-root": {
        fontWeight: "bold",
      },
    },
    labelCenter: {
      display: "inline-flex",
      alignItems: "center",
    },
    helpIcon: {
      paddingLeft: 10,
      color: theme.palette.info.main,
    },
  })
);

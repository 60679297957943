import React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  InputProps,
  InputAdornment,
} from "@mui/material";
import { Done, PhoneIphone, Email } from "@mui/icons-material";
import InputMask from "react-input-mask";
import { useStyles } from "components/common/CustomInput/CustomInputStyle";

interface Props {
  label: string;
  value: string | undefined;
  id: string;
  error: boolean;
  isDisabled?: boolean;
  inputProps: InputProps;
  mask?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const CustomInput: React.FC<Props> = ({
  label,
  value,
  id,
  error,
  onChange,
  isDisabled,
  inputProps,
  mask,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <InputLabel className={classes.label} htmlFor={id}>
        {label}
      </InputLabel>
      {mask ? (
        <InputMask
          mask="(+99) 999-999-999"
          value={value}
          onChange={onChange}
          disabled={isDisabled}
        >
          {(inputProps: InputProps) => (
            <Input
              className={classes.input}
              error={error}
              disabled={isDisabled}
              id={id}
              endAdornment={
                <InputAdornment position="end">
                  {!error && value && value.length > 5 ? (
                    <Done />
                  ) : (
                    <PhoneIphone />
                  )}
                </InputAdornment>
              }
              {...inputProps}
            />
          )}
        </InputMask>
      ) : (
        <Input
          className={classes.input}
          value={value !== undefined && value}
          id={id}
          onChange={onChange}
          error={error}
          disabled={isDisabled}
          endAdornment={
            <InputAdornment position="end">
              {!error && value && value.length > 0 ? <Done /> : <Email />}
            </InputAdornment>
          }
          {...inputProps}
        />
      )}
    </FormControl>
  );
};

export default CustomInput;

import React, { useState } from "react";
import {
  Paper,
  ButtonBase,
  Typography,
  IconButton,
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogContentText,
  Link,
} from "@mui/material";
import { HelpCenter, Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ModuleInfoHelp, ModalInfoHelpItem } from "types";
import { useStyles } from "components/common/CustomComplexButton/CustomComplexButtonStyle";

interface CustomComplexButtonProps {
  id?: number;
  title: string;
  image: string;
  onClick: (id: number | undefined, title: string) => void;
  help?: ModuleInfoHelp;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

const CustomComplexButton: React.FC<CustomComplexButtonProps> = ({
  id,
  title,
  image,
  onClick,
  help,
}): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScrean = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Paper elevation={9} className={classes.root}>
      {help && (
        <div className={classes.infoBox}>
          <CustomTooltip
            title={help.tooltipLabel}
            TransitionComponent={Grow}
            arrow
            placement="top"
            TransitionProps={{ timeout: 600 }}
          >
            <IconButton onClick={onClickOpen} className={classes.button}>
              <HelpCenter color="secondary" />
            </IconButton>
          </CustomTooltip>
        </div>
      )}

      <ButtonBase
        focusRipple
        onClick={() => onClick(id, title)}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <div className={classes.titleBox}>
          <Typography
            component="span"
            variant="subtitle1"
            className={classes.imageTitle}
          >
            {title}
          </Typography>
        </div>
      </ButtonBase>
      {help && (
        <Dialog fullScreen={fullScrean} open={isOpen} onClose={onClickOpen}>
          <DialogTitle className={classes.dialogTitle}>
            {help.modalTitle.toUpperCase()}
          </DialogTitle>
          <div className={classes.closeButton}>
            <IconButton onClick={onClickOpen}>
              <Close />
            </IconButton>
          </div>
          <DialogContent>
            {help.items.map((item: ModalInfoHelpItem, index: number) => {
              return (
                <DialogContentText
                  className={item.isBold ? classes.contentBold : ""}
                  key={index}
                >
                  {item.link ? (
                    <Link href={item.link} target="_blank">
                      {item.content}
                    </Link>
                  ) : (
                    item.content
                  )}
                </DialogContentText>
              );
            })}
          </DialogContent>
        </Dialog>
      )}
    </Paper>
  );
};

export default CustomComplexButton;

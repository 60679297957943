import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiButton-root": {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        "&:hover": {
          backgroundColor: theme.palette.error.light,
        },
      },
    },
    iconRight: {
      paddingLeft: 10,
    },
    iconLeft: {
      paddingRight: 10,
    },
  })
);

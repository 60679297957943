import React from "react";
import { Grid } from "@mui/material";
import classNames from "classnames";
import CustomComplexButton from "components/common/CustomComplexButton/CustomComplexButton";
import { CustomStepProps, ModuleInfo } from "types";
import { useStyles } from "components/common/BattonsSelect/ButtonsSelectStyle";

const ButtonsSelect: React.FC<CustomStepProps> = ({
  goNext,
  setResult,
  buttonsOptions: genericModuleInfo,
}): JSX.Element => {
  const classes = useStyles();
  const buttonsOptions = genericModuleInfo as ModuleInfo[];

  const rootClasses = classNames({
    [classes.root]: true,
    [classes.between]: buttonsOptions.length > 2,
    [classes.center]: buttonsOptions.length < 3,
  });

  const onClick = (id: number | undefined, title: string) => {
    setResult({
      userSelection: { id, title },
    });
    if (id) {
      goNext(id);
    }
  };

  return (
    <Grid container justifyContent="center" className={classes.main}>
      <Grid item xs={12} sm={12} md={10} className={rootClasses}>
        {buttonsOptions.map(
          ({ id, title, image, help }: ModuleInfo, index: number) => {
            return (
              <CustomComplexButton
                key={index}
                id={id}
                title={title}
                image={image}
                help={help}
                onClick={onClick}
              />
            );
          }
        )}
      </Grid>
    </Grid>
  );
};

export default ButtonsSelect;

import { createTheme } from "@mui/material/styles";
import { red, blue, grey, green } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      light: "#a9d9ff",
      main: "#005BA2",
      dark: blue[900],
    },
    secondary: {
      light: grey[300],
      main: "#F2F5F9",
      dark: grey[800],
    },
    error: {
      light: "#A40E29",
      main: "#A40E29",
      dark: red[800],
    },
    info: {
      light: "#0069bb",
      main: "#005BA2",
      dark: blue[900],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: blue[900],
    },
  },
});

export default theme;

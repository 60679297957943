import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import classNames from "classnames";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { removeSelectedStep } from "redux/stepSlice";
import {
  CustomStepProps,
  ChoiceList,
  ChoiceListItem,
  AnswerResult,
  ListsId,
  ReadStep,
  WaySelection,
} from "types";
import { useStyles } from "components/features/GazSummary/GazSummaryStyle";

const GazSummary: React.FC<CustomStepProps> = ({
  answers: genericAnswers,
  lists: genericLists,
}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const answers = genericAnswers as AnswerResult[];
  const results = useAppSelector((store) => store.step.stepResults);
  const lists = genericLists as Record<ListsId, ChoiceList>;
  const [answersState, setAnswersState] = useState<AnswerResult[]>([]);

  useEffect(() => {
    let answersResult: AnswerResult[] = [];
    const checkContainer = results[36];
    if (checkContainer && checkContainer.userSelection.id === 38) {
      dispatch(removeSelectedStep(37));
    }
    answers.forEach((item: AnswerResult) => {
      const step: ReadStep = results[item.stepId];
      if (step) {
        let result: string = "";
        if (typeof step.userSelection === "string") {
          const listId = step.userSelection.substring(
            0,
            step.userSelection.lastIndexOf("/")
          ) as ListsId;
          const listItem = lists[listId].items.find(
            (itemList: ChoiceListItem) => itemList.id === step.userSelection
          );
          if (listItem) {
            result = listItem.title;
          }
        } else {
          const waySelection = step.userSelection as WaySelection;
          result = waySelection.title;
        }
        answersResult.push({
          ...item,
          answer: result,
        });
      }
    });
    setAnswersState(answersResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return (
    <div className={classes.root}>
      <div className={classes.back}>
        <Grid container className={classes.main}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={16} className={classes.info}>
              <Typography className={classes.bold} textAlign="center">{`Cześć ${
                results[0] ? results[0].userName : ""
              } !`}</Typography>
              <Typography
                className={classNames(classes.bold, classes.paddingBottom)}
                textAlign="center"
              >
                Nasz system na postawie udzielonych przez Ciebie odpowiedzi
                ustalił że:
              </Typography>
              {answersState.map((item: AnswerResult) => {
                return (
                  <div key={item.stepId} className={classes.resultRow}>
                    <Typography component="span">{item.question}</Typography>
                    <Typography className={classes.answer} component="span">
                      {item.answer}
                    </Typography>
                  </div>
                );
              })}
              {results[33] &&
                results[33].userSelection === `${ListsId.list3}/1` && (
                  <Typography className={classes.withoutGaz}>
                    Spawanie metodą MMA nie wymaga stosowania gazu osłonowego
                  </Typography>
                )}
              {results[33] &&
                results[33].userSelection === `${ListsId.list3}/4` && (
                  <Typography className={classes.withoutGaz}>
                    Jeśli chcesz uzyskać więcej informacji na temat gazów
                    stosowanych w metodzie spawania gazowego – zapraszamy do
                    kontaktu z Tomsystem
                  </Typography>
                )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GazSummary;

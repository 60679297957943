import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 210,
      height: 185,
      position: "relative",
      margin: "30px 5px",
      "@media (min-width: 1200px)": {
        margin: "30px 15px",
      },
      "@media (min-width: 1400px)": {
        margin: "30px 38px",
      },
      "@media (min-width: 1840px)": {
        margin: "30px 70px",
      },
      "@media (min-width: 2200px)": {
        margin: "30px 100px",
      },
      "&.MuiPaper-root": {
        borderRadius: 10,
      },
    },
    infoBox: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: -3,
      right: -3,
      height: 40,
      width: 40,
      zIndex: 99,
    },
    image: {
      position: "relative",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
        height: 100,
      },
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 0.15,
        },
        "& $imageMarked": {
          opacity: 0,
        },
        "& $titleBox": {
          transform: "scale(1.1)",
          transition: "1s",
        },
      },
    },
    button: {
      "&.MuiButtonBase-root": {
        backgroundColor: theme.palette.info.main,
        position: "absolute",
        "&:hover": {
          backgroundColor: theme.palette.error.main,
        },
        "&:hover .MuiSvgIcon-root": { color: "#fff" },
      },
    },
    dialogTitle: {
      color: theme.palette.info.main,
      textAlign: "center",
    },
    titleBox: {
      transition: "1s",
      transform: "scale(1)",
      padding: 20,
    },
    focusVisible: {},
    imageButton: {
      maxHeight: "60px",
      position: "absolute",
      left: "43vw",
      right: 0,
      top: "8vh",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      borderRadius: 10,
      opacity: 0.4,
      transition: theme.transitions.create("opacity"),
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
      borderRadius: 10,
      transition: "3s",
      transform: "scale(1)",
    },
    imageTitle: {
      "&.MuiTypography-root": {
        fontFamily: "Roboto Slab",
        color: "#fff",
        fontSize: "20px",
        position: "relative",
      },
    },
    imageMarked: {
      position: "absolute",
      bottom: -40,
      left: "calc(50% - 20px)",
      transition: theme.transitions.create("opacity"),
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    contentBold: {
      "&.MuiTypography-root": {
        fontWeight: 600,
      },
    },
  })
);

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      position: "relative",
    },
    root: {
      padding: "20px 10px 0",
      height: "calc(100vh - 258px)",
      overflowY: "auto",
      flexDirection: "column",
      display: "flex",
    },
    table: {
      fontFamily: theme.typography.fontFamily,
    },
    head: {
      fontWeight: "bold",
    },
    titleCell: {
      "&.MuiTableCell-root": {
        paddingLeft: 0,
        borderBottom: "none",
      },
    },
    cell: {
      "&.MuiTableCell-root": {
        display: "inline-flex",
        padding: 3,
        borderBottom: "none",
        alignItems: "center",
      },
    },
    paddingRight: {
      paddingRight: 10,
    },
    text: {
      "&.MuiTypography-root": {
        fontSize: 14,
        lineHeight: "25px",
      },
    },
    bold: {
      "&.MuiTypography-root": {
        fontWeight: 600,
      },
    },
    rowCenter: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    helpIcon: {
      paddingLeft: 10,
      color: theme.palette.info.main,
    },
  })
);

import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Link,
  Fade,
} from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { removeSelectedStep } from "redux/stepSlice";
import { styled } from "@mui/material/styles";
import { ChoiceListItem, CustomStepProps, ListsId } from "types";
import KnowedgeBase from "components/common/KnowledgeBase/KnowledgeBase";
import { useStyles } from "components/features/OptionsList/OptionsListStyle";
import { listsData } from "entryData";

type Result = {
  userSelection: string | null;
};

const emptyResult: Result = {
  userSelection: null,
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

const OptionsList: React.FC<CustomStepProps> = ({
  result,
  setResult,
  listId: genericListId,
  knowledgeBaseLink: rawKnowledgeBaseLink,
  knowledgeBaseTitle: rawKnowledgeBaseTitle,
  storeItem: genericStoreItem,
  currentStepId,
}): JSX.Element => {
  const classes = useStyles();
  const stepResults = useAppSelector((state) => state.step.stepResults);
  const listId = genericListId as ListsId;
  const storeItem = genericStoreItem as number | undefined;
  const knowledgeBaseLink = rawKnowledgeBaseLink as string;
  const knowledgeBaseTitle = rawKnowledgeBaseTitle as string;
  const dispatch = useAppDispatch();
  const { items } = listsData[listId];
  const { userSelection } = (result as Result) || emptyResult;
  const [filteredItems, setFilteredItems] = useState<
    ChoiceListItem[] | undefined
  >();

  useEffect(() => {
    if (storeItem) {
      const findedListId = stepResults[storeItem].userSelection;
      const availableItems = items.filter(
        (item: ChoiceListItem) =>
          item.available && item.available.includes(findedListId)
      );
      if (availableItems.length) {
        setFilteredItems(availableItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result: Result = {
      userSelection: e.target.value,
    };
    setResult(result);
    if (listId === ListsId.list1 && currentStepId === 8 && stepResults[11]) {
      dispatch(removeSelectedStep(11));
    }
    if (listId === ListsId.list1 && currentStepId === 31 && stepResults[33]) {
      dispatch(removeSelectedStep(33));
    }
    if (listId === ListsId.list2 && currentStepId === 35 && stepResults[37]) {
      dispatch(removeSelectedStep(37));
    }
  };

  const labelContent = ({
    title,
    description,
    linkText,
    linkUrl,
    tooltip,
  }: ChoiceListItem) => {
    return (
      <>
        <Typography component="span" className={classes.title}>
          {title}
        </Typography>
        {!tooltip && description && (
          <Typography component="span"> {description} </Typography>
        )}
        {tooltip && description && (
          <CustomTooltip
            title={description}
            TransitionComponent={Fade}
            arrow
            placement="right"
            TransitionProps={{ timeout: 600 }}
          >
            <HelpOutlineOutlined className={classes.helpIcon} />
          </CustomTooltip>
        )}
        {linkText && linkUrl && (
          <Link href={linkUrl} target="_blank">
            {linkText}
          </Link>
        )}
      </>
    );
  };

  return (
    <Grid justifyContent="center" display="flex" className={classes.container}>
      <Grid item xs={12} sm={12} md={10} className={classes.main}>
        <div className={classes.root}>
          <FormControl className={classes.form} component="fieldset">
            <RadioGroup value={userSelection} onChange={onChange}>
              {filteredItems
                ? filteredItems.map((item: ChoiceListItem) => {
                    return (
                      <FormControlLabel
                        className={classes.item}
                        color="error"
                        key={item.id}
                        label={
                          <span
                            className={item.tooltip ? classes.labelCenter : ""}
                          >
                            {labelContent(item)}
                          </span>
                        }
                        value={item.id}
                        control={<Radio />}
                      />
                    );
                  })
                : items.map((item: ChoiceListItem) => {
                    return (
                      <FormControlLabel
                        className={classes.item}
                        color="error"
                        key={item.id}
                        label={
                          <span
                            className={item.tooltip ? classes.labelCenter : ""}
                          >
                            {labelContent(item)}
                          </span>
                        }
                        value={item.id}
                        control={<Radio />}
                      />
                    );
                  })}
            </RadioGroup>
          </FormControl>
        </div>

        {knowledgeBaseLink && (
          <KnowedgeBase
            title={knowledgeBaseTitle}
            knowledgeBaseLink={knowledgeBaseLink}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default OptionsList;

import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Grow,
} from "@mui/material";
import { LocalGroceryStore } from "@mui/icons-material";
import { ApiResponseItem } from "types";
import { formatPrice, cutText } from "functions";
import { useStyles } from "components/common/CarouselItem/CarouselItemStyle";

interface Props {
  item: ApiResponseItem;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}));

const CarouselItem: React.FC<Props> = ({ item }) => {
  const { id, name, description, url, price, sku } = item;
  const classes = useStyles();

  return (
    <Card elevation={16} className={classes.root} sx={{ maxWidth: 500 }}>
      <CardHeader className={classes.header} title={name} />
      <CardMedia
        className={classes.image}
        component="img"
        image={url}
        alt={`card/${id}`}
      />
      <CardContent className={classes.content}>
        <Typography variant="body2">{cutText(description, 90)}</Typography>
      </CardContent>
      <CardActions className={classes.footer}>
        <div className={classes.iconBox}>
          <a
            href={`/api/addToCart?sku=${sku}`}
            target="_blank"
            rel="noreferrer"
          >
            <CustomTooltip
              title="Dodaj do koszyka"
              TransitionComponent={Grow}
              arrow
              placement="top"
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                className={classes.button}
              >
                <LocalGroceryStore color="info" />
              </IconButton>
            </CustomTooltip>
          </a>
          <Typography className={classes.price}>{formatPrice(price)} PLN</Typography>
        </div>
      </CardActions>
    </Card>
  );
};

export default CarouselItem;

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    dialogTitle: {
      color: theme.palette.info.main,
      textAlign: "center",
      "&.MuiTypography-root": {
        padding: "16px 60px",
      },
    },
    inputsBox: {
      minHeight: 200,
      minWidth: 370,
      padding: "40px 20px 20px",
      "&.MuiGrid-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    spaceAround: {
      "&.MuiDialogActions-root": {
        justifyContent: "space-around",
      },
    },
  })
);

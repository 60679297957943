import React from "react";
import classNames from "classnames";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Link,
  Fade,
} from "@mui/material";
import {
  Check,
  Dangerous,
  EmojiObjects,
  HelpOutlineOutlined,
} from "@mui/icons-material";
import KnowledgeBase from "components/common/KnowledgeBase/KnowledgeBase";
import { CustomStepProps, ReaderInfo, IconType, ReaderItemInfo } from "types";
import { CustomTooltip } from "functions";
import { useStyles } from "components/features/ContentReader/ContentReaderStyle";

const ContentReader: React.FC<CustomStepProps> = ({
  data: genericData,
  knowledgeBaseLink: rawKnowledgeBaseLink,
  knowledgeBaseTitle: rawKnowledgeBaseTitle,
  isShop: genericIsShop,
}): JSX.Element => {
  const classes = useStyles();

  const data = genericData as ReaderInfo[];
  const knowledgeBaseLink = rawKnowledgeBaseLink as string;
  const knowledgeBaseTitle = rawKnowledgeBaseTitle as string;
  const isShop = genericIsShop as boolean;

  const iconContent = (type: IconType) => {
    switch (type) {
      case IconType.dangerous:
        return (
          <Dangerous
            className={classes.paddingRight}
            color="error"
            fontSize="small"
          />
        );
      case IconType.check:
        return (
          <Check
            className={classes.paddingRight}
            color="success"
            fontSize="small"
          />
        );
      case IconType.warning:
        return (
          <EmojiObjects
            className={classes.paddingRight}
            color="warning"
            fontSize="small"
          />
        );
      default:
        return (
          <Check
            className={classes.paddingRight}
            color="success"
            fontSize="small"
          />
        );
    }
  };

  const dataContent = (
    { content, isBold, icon, items, link }: ReaderInfo,
    value: number
  ) => {
    const contentClasses = classNames({
      [classes.text]: true,
      [classes.bold]: isBold,
    });
    if (!items && !link) {
      if (icon !== undefined) {
        return (
          <div key={`text${value}icon`} className={classes.rowCenter}>
            {iconContent(icon)}
            <Typography component="span" className={contentClasses}>
              {content}
            </Typography>
          </div>
        );
      } else {
        if (content.length > 0) {
          return (
            <Typography key={`text${value}`} className={contentClasses}>
              {content}
            </Typography>
          );
        } else {
          return <br key={`space${value}`} />;
        }
      }
    }
    if (items !== undefined && icon !== undefined) {
      return (
        <Table key={`first${value}`} className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.titleCell}>{content}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: ReaderItemInfo, index: number) => {
              return (
                <TableRow key={`second${index}`}>
                  <TableCell className={classes.cell}>
                    {iconContent(icon)}
                    {item.title}
                    {item.description && (
                      <CustomTooltip
                        title={item.description}
                        TransitionComponent={Fade}
                        arrow
                        placement="right"
                        TransitionProps={{ timeout: 600 }}
                      >
                        <HelpOutlineOutlined className={classes.helpIcon} />
                      </CustomTooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    if (link !== undefined) {
      return (
        <Link key={`link${value}`} href={link} target="_blank">
          {content}
        </Link>
      );
    }
  };

  return (
    <Grid justifyContent="center" display="flex">
      <Grid item xs={12} sm={12} md={10} className={classes.main}>
        <div className={classes.root}>
          {data.map((data: ReaderInfo, value: number) => {
            return dataContent(data, value);
          })}
          {knowledgeBaseLink && (
            <KnowledgeBase
              title={knowledgeBaseTitle}
              knowledgeBaseLink={knowledgeBaseLink}
              isShop={isShop}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ContentReader;

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&.MuiButtonBase-root": {
        backgroundColor: "#fff",
        position: "absolute",
        "&:hover": {
          backgroundColor: theme.palette.error.main,
        },
        "&:hover .MuiSvgIcon-root": { color: "#fff" },
      },
      right: 20,
      bottom: 30,
    },
  })
);

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import image from "images/introduction.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 160px)",
      position: "relative",
      "&.MuiGrid-root": {
        display: "flex",
        flexDirection: "column-reverse",
      },
    },
    box: {
      height: 220,
      display: "flex",
      flexDirection: "row",
      zIndex: 10,
    },
    content: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: "6.39%",
      height: "calc(100vh - 160px)",
      background: `url(${image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      mixBlendMode: "normal",
      backgroundColor: theme.palette.secondary.main,
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputBox: {
      padding: "5px 5px 0",
      "&.MuiPaper-root": {
        backgroundColor: "rgba(242, 245, 249, 0.6)",
      },
    },
    input: {
      width: 218,
      height: 45,
    },
    description: {
      height: "100%",
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      alignItems: "center",
    },
    text: {
      "&.MuiTypography-root": {
        fontSize: 24,
        fontWeight: 700,
        fontStyle: "normal",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    otherText: {
      "&.MuiTypography-root": {
        color: theme.palette.info.main,
      },
    },
  })
);

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 235px)",
      overflowY: "auto",
      flexDirection: "column",
      display: "flex",
    },
    imagesList: {
      width: "100vw",
    },
    imageListItem: {
      "&.MuiImageListItem-root": {
        display: "inline-flex",
      },
    },
    imageListItemLeft: {
      "&.MuiImageListItem-root": {
        justifyContent: "left",
      },
    },
    imageListItemRight: {
      "&.MuiImageListItem-root": {
        justifyContent: "right",
      },
    },
    image: {
      position: "relative",
      transition: ".5s",
      filter: "opacity(.7)",
      width: "100%",
      height: "100%",
      maxWidth: 1300,
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
        height: 100,
      },
      "&:hover, &$focusVisible": {
        filter: "opacity(1)",
        transition: "1s",
      },
    },
    focusVisible: {},
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "#fff",
      backgroundRepeat: "no-repeat",
      borderRadius: 10,
      transition: "3s",
      transform: "scale(1)",
    },
    paddingRight: {
      paddingRight: 10,
    },
    text: {
      "&.MuiTypography-root": {
        fontSize: 14,
        lineHeight: "25px",
      },
    },
    bold: {
      "&.MuiTypography-root": {
        fontWeight: 600,
      },
    },
    rowCenter: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    head: {
      fontWeight: "bold",
    },
    table: {
      fontFamily: theme.typography.fontFamily,
    },
    titleCell: {
      "&.MuiTableCell-root": {
        paddingLeft: 0,
        borderBottom: "none",
      },
    },
    cell: {
      "&.MuiTableCell-root": {
        display: "inline-flex",
        padding: 3,
        borderBottom: "none",
        alignItems: "center",
      },
    },
    helpIcon: {
      paddingLeft: 10,
      color: theme.palette.info.main,
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  })
);

import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { addUserData, selectApiResult } from "redux/apiSlice";
import CustomDirectButton from "components/common/CustomDirectButton/CustomDirectButton";
import CustomInput from "components/common/CustomInput/CustomInput";
import { UserInfo } from "types";
import { useStyles } from "components/features/SendingQuestionModal/SendingQuestionModalStyle";

interface Props {
  isOpen: boolean;
  onClose: (isClose: boolean) => void;
  userName: string | undefined;
  file: File | null;
}

const emptyUser: UserInfo = {
  name: "",
  email: "",
  phone: "(+48)",
};

const SendingQuestionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  userName,
  file,
}): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const apiSelector = useAppSelector(selectApiResult);
  const fullScrean = useMediaQuery(theme.breakpoints.down("md"));
  const [userData, setUserData] = useState<UserInfo>({
    ...emptyUser,
    name: userName !== undefined ? userName : "",
  });
  const [isError, setIsError] = useState<
    Record<keyof Omit<UserInfo, "name">, boolean>
  >({
    email: false,
    phone: false,
  });

  useEffect(() => {
    if (!isOpen) {
      setUserData({
        ...emptyUser,
        name: userName !== undefined ? userName : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setIsError({
      ...isError,
      email: !userData.email.includes("@") && userData.email.length !== 0,
      phone:
        userData.phone !== undefined &&
        userData.phone.length > 5 &&
        userData.phone.replace("_", "").length !== 17,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onClick = () => {
    if (file !== null) {
      dispatch(
        addUserData({
          userData,
          pdf: file,
        })
      );
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

  return (
    <Dialog fullScreen={fullScrean} open={isOpen} keepMounted>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={apiSelector.isSending}
      />
      <DialogTitle className={classes.dialogTitle}>
        Wyślij zapytanie do sklepu
      </DialogTitle>
      <div className={classes.closeButton}>
        <IconButton onClick={() => onClose(false)}>
          <Close />
        </IconButton>
      </div>
      <DialogContent dividers>
        <DialogContentText textAlign="center">{`${
          userName !== undefined ? userName + " podaj" : "Podaj"
        } swój adres email oraz numer telefonu, a my prześlemy konfigurację oraz przygotowaną specjalnie dla Ciebie ofertę.`}</DialogContentText>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={8} className={classes.inputsBox}>
            {apiSelector.isSending ? (
              <CircularProgress />
            ) : (
              <>
                <CustomInput
                  label="Adres email"
                  value={userData.email}
                  onChange={onChange}
                  id="email"
                  error={isError.email}
                  inputProps={{
                    type: "email",
                  }}
                />
                <CustomInput
                  label="Telefon"
                  value={userData.phone}
                  onChange={onChange}
                  id="phone"
                  mask={true}
                  error={isError.phone}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.spaceAround}>
        <CustomDirectButton
          label="Wróć"
          isLeft
          onClick={() => onClose(false)}
        />
        <CustomDirectButton
          label="Wyślij"
          onClick={onClick}
          isDisabled={
            userData.email.length === 0 ||
            isError.email ||
            isError.phone ||
            userData.phone.length === 5
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default SendingQuestionModal;

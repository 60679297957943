import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      backgroundColor: theme.palette.secondary.main,
    },
    header: {
      height: 80,
      backgroundColor: "#fff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
    },
    logoBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

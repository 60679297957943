import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Grid, Typography, Accordion } from "@mui/material";
import { Check, Dangerous } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import KnowedgeBase from "components/common/KnowledgeBase/KnowledgeBase";
import { CustomStepProps, AccordionInfo, ListsId } from "types";
import { removeSelectedStep } from "redux/stepSlice";
import {
  useStyles,
  AccordionSummary,
  AccordionDetails,
} from "components/features/AccordionPlatform/StyleAccordionPlatform";

const AccordionPlatform: React.FC<CustomStepProps> = ({
  data: genericData,
  knowledgeBaseLink: rawKnowledgeBaseLink,
  knowledgeBaseTitle: rawKnowledgeBaseTitle,
  goNext,
}): JSX.Element => {
  const classes = useStyles();
  const data = genericData as AccordionInfo[];
  const knowledgeBaseLink = rawKnowledgeBaseLink as string;
  const knowledgeBaseTitle = rawKnowledgeBaseTitle as string;
  const stepResults = useAppSelector((state) => state.step.stepResults);
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<number | false>(false);

  useEffect(() => {
    const checkingStep = stepResults[33];
    if (
      checkingStep &&
      (checkingStep.userSelection === `${ListsId.list3}/1` ||
        checkingStep.userSelection === `${ListsId.list3}/4`)
    ) {
      goNext(38);
    }
    if (stepResults[36] !== null) {
      dispatch(removeSelectedStep(36));
    }
    if (stepResults[37] !== null) {
      dispatch(removeSelectedStep(37));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange =
    (id: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? id : false);
    };

  const answerContent = (answer: boolean) => {
    const answerClasses = classNames({
      [classes.answerPadding]: true,
      [classes.green]: answer,
      [classes.red]: !answer,
    });
    return (
      <div className={classes.answer}>
        {answer ? <Check color="success" /> : <Dangerous color="error" />}
        <Typography className={answerClasses}>
          {answer ? "PRAWDA" : "FAŁSZ"}
        </Typography>
      </div>
    );
  };

  return (
    <Grid justifyContent="center" display="flex">
      <Grid item xs={12} sm={12} md={10} className={classes.main}>
        <div className={classes.root}>
          {data.map((item: AccordionInfo) => {
            return (
              <Accordion
                key={item.id}
                expanded={expanded === item.id}
                onChange={onChange(item.id)}
                disableGutters
                elevation={0}
                square
                className={expanded === item.id ? classes.test : ""}
              >
                <AccordionSummary>
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {answerContent(item.answer)}
                  <Typography>{item.content}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {knowledgeBaseLink && (
            <KnowedgeBase
              title={knowledgeBaseTitle}
              knowledgeBaseLink={knowledgeBaseLink}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default AccordionPlatform;

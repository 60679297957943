import React from "react";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { ImageBoxInfo } from "types";
import { useStyles } from "components/common/ImageBox/ImageBoxStyle";

interface Props extends ImageBoxInfo {
  selectedId: number;
}

const ImageBox: React.FC<Props> = ({
  image,
  title,
  id,
  selectedId,
}): JSX.Element => {
  const classes = useStyles();
  const imageClasses = classNames({
    [classes.image]: true,
    [classes.selected]: id === selectedId,
  });

  return (
    <div className={classes.root}>
      <div
        className={imageClasses}
        style={{ backgroundImage: `url(${image})` }}
      >
        <Typography textAlign="center" className={classes.title}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default ImageBox;

import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
    },
    main: {
      height: "100%",
      padding: "16px 0",
      position: "relative",
    },
    root: {
      height: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
  })
);

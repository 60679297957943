import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classNames from "classnames";
import CustomRadioButton from "components/common/CustomRadioButton/CustomRadioButton";
import KnowedgeBase from "components/common/KnowledgeBase/KnowledgeBase";
import { ChoiceListItem, CustomStepProps, ListsId } from "types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { removeSelectedStep } from "redux/stepSlice";
import { useStyles } from "components/common/ImagesList/ImagesListStyle";
import { listsData } from "entryData";

type Result = {
  userSelection: string | null;
};

const emptyResult: Result = {
  userSelection: null,
};

const ImagesList: React.FC<CustomStepProps> = ({
  result,
  setResult,
  listId: genericListId,
  knowledgeBaseLink: rawKnowledgeBaseLink,
  knowledgeBaseTitle: rawKnowledgeBaseTitle,
  storeItem: genericStoreItem,
  currentStepId,
}): JSX.Element => {
  const classes = useStyles();
  const stepResults = useAppSelector((state) => state.step.stepResults);
  const listId = genericListId as ListsId;
  const storeItem = genericStoreItem as number | undefined;
  const knowledgeBaseLink = rawKnowledgeBaseLink as string;
  const knowledgeBaseTitle = rawKnowledgeBaseTitle as string;
  const dispatch = useAppDispatch();
  const { items } = listsData[listId];
  const { userSelection } = (result as Result) || emptyResult;
  const [filteredItems, setFilteredItems] = useState<
    ChoiceListItem[] | undefined
  >();
  const [selectedItem, setSelectedItem] = useState<string | null>(
    userSelection
  );

  const rootClasses = classNames({
    [classes.root]: true,
    [classes.between]: filteredItems
      ? filteredItems.length > 2
      : items.length > 2,
    [classes.center]: filteredItems
      ? filteredItems.length < 3
      : items.length < 3,
  });

  useEffect(() => {
    if (storeItem) {
      const findedListId = stepResults[storeItem].userSelection;
      const availableItems = items.filter(
        (item: ChoiceListItem) =>
          item.available && item.available.includes(findedListId)
      );
      if (availableItems.length) {
        setFilteredItems(availableItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (id: string) => {
    setSelectedItem(id);
    const result: Result = {
      userSelection: id,
    };
    setResult(result);
    if (listId === ListsId.list3 && currentStepId === 33 && stepResults[35]) {
      dispatch(removeSelectedStep(35));
    }
  };

  return (
    <Grid container justifyContent="center" className={classes.main}>
      <Grid item xs={12} sm={12} md={10} className={rootClasses}>
        {filteredItems
          ? filteredItems.map((item: ChoiceListItem) => {
              return (
                <CustomRadioButton
                  key={item.id}
                  id={item.id}
                  help={item.description ? item.description : ""}
                  image={item.image ? item.image : ""}
                  selectedItem={selectedItem}
                  onClick={onClick}
                />
              );
            })
          : items.map((item: ChoiceListItem) => {
              return (
                <CustomRadioButton
                  key={item.id}
                  id={item.id}
                  help={item.description ? item.description : ""}
                  image={item.image ? item.image : ""}
                  onClick={onClick}
                  selectedItem={selectedItem}
                />
              );
            })}
      </Grid>
      <div className={classes.knowedgeBaseBox}>
        {knowledgeBaseLink && (
          <KnowedgeBase
            title={knowledgeBaseTitle}
            knowledgeBaseLink={knowledgeBaseLink}
          />
        )}
      </div>
    </Grid>
  );
};

export default ImagesList;

import OptionsList from "components/features/OptionsList/OptionsList";
import Introduction from "components/features/Itroduction/Introduction";
import HoursSlider from "components/features/HoursSlider/HoursSlider";
import ImageReader from "components/features/ImageReader/ImageReader";
import ContentReader from "components/features/ContentReader/ContentReader";
import ButtonsSelect from "components/common/BattonsSelect/ButtonsSelect";
import MaterialThickness from "components/features/MaterialThickness/MaterialThickness";
import AccordionPlatform from "components/features/AccordionPlatform/AccordionPlatform";
import TechnologySummary from "components/features/TechnologySummary/TechnologySummary";
import GazSummary from "components/features/GazSummary/GazSummary";
import WeldingCompetences from "components/features/WeldingCompetences/WeldingCompetences";
import selectCompetence from "images/selectCompetences.png";
import selectPositions from "images/selectPositions.png";
import selectTechnology from "images/selectTechnology.png";
import selectCylinder from "images/selectCylinder.png";
import selectContainer from "images/selectContainer.png";
import selectGaz from "images/selectGaz.png";
import selectWay1 from "images/selectWay1.jpeg";
import selectWay2 from "images/selectWay2.jpeg";
import mainImageSelectWay from "images/mainImageSelectWay.png";
import mainImageTechnologyPath from "images/mainImageTechnologyPath.png";
import mainImageGazPath from "images/mainImageGazPath.png";
import mainImageCompetences from "images/mainImageCompetences.png";
import mainImagePositions from "images/mainImagePositions.png";
import cuttingMaterial from "images/materialThicknessForCutting.png";
import weldedMaterial from "images/materialThicknessForWelded.png";
import personalEquipment from "images/personalEquipment.png";
import weldingPositionsBackground from "images/weldingPositions.png";
import welder1 from "images/welder1.png";
import welder2 from "images/welder2.png";
import welder3 from "images/welder3.png";
import welder4 from "images/welder4.png";
import gazMethod from "images/gazMethod.png";
import laserMethod from "images/laserMethod.png";
import migmagMethod from "images/migmagMethod.png";
import mmaMethod from "images/mmaMethod.png";
import plazmMethod from "images/plazmMethod.png";
import tigMethod from "images/tigMethod.png";
import competencesImage1 from "images/competencesImage1.png";
import competencesImage2 from "images/competencesImage2.png";
import competencesImage3 from "images/competencesImage3.png";
import competencesImage4 from "images/competencesImage4.png";
import competencesImage5 from "images/competencesImage5.png";
import competencesImage6 from "images/competencesImage6.png";
import competencesImage7 from "images/competencesImage7.png";

import {
  ChoiceList,
  ListsId,
  MarksId,
  GenericStep,
  ModuleInfo,
  ReaderInfo,
  IconType,
  SliderMarks,
  AccordionInfo,
  ImageBoxInfo,
  AnswerResult,
  AnswerPath,
  ImageModalInfo,
} from "types";
import ImagesList from "components/common/ImagesList/ImagesList";

export const firstStepId: number = 0;

export const wayChoices: ModuleInfo[] = [
  {
    id: 5,
    title: "Proces rozdzielania (cięcie)",
    image: selectWay1,
  },
  {
    id: 8,
    title: "Proces łączenia (spawanie)",
    image: selectWay2,
  },
];

export const cylinderChoices: ModuleInfo[] = [
  {
    id: 38,
    title: "Zbiornik",
    image: selectContainer,
    help: {
      tooltipLabel: "Dowiedz się więcej o wyborze Zbiornika",
      modalTitle: "Zbiornik",
      items: [
        { content: "Uwaga!", isBold: true },
        {
          content:
            "Wybór zbiornika oznacza, że posiadasz wewnętrzną instalację opartą na stacjonarnym zbiorniku. Jeżeli nie masz odpowiedniej instalacji ze zbiornika i chcesz dowiedzieć się co należy zrobić aby ją założyć - skontaktuj się z nami",
        },
        {
          content: "https://tomsystem.pl/kontakt/",
          link: "https://tomsystem.pl/kontakt/",
        },
      ],
    },
  },
  {
    id: 37,
    title: "Butla",
    image: selectCylinder,
    help: {
      tooltipLabel: "Dowiedz się więcej o wyborze Butla",
      modalTitle: "Butla",
      items: [
        { content: "Uwaga!", isBold: true },
        {
          content:
            "Wybór butli oznacza, że będziesz spawać w osłonie gazu (lub mieszaniny gazów), który będzie pobierany z butli gazowej",
        },
      ],
    },
  },
];

export const pathChoices: ModuleInfo[] = [
  {
    id: 20,
    title: "Kompetencje spawalnicze",
    image: selectCompetence,
  },
  {
    id: 21,
    title: "Wybór pozycji spawalniczych",
    image: selectPositions,
  },
  {
    id: 2,
    title: "Wybierz swoją technologię",
    image: selectTechnology,
  },
  {
    id: 30,
    title: "Wybierz gaz",
    image: selectGaz,
  },
];

export const filteringVentilationSolutions: string[] = [
  "Filtrowentylacja oparta o filtr mobilny stanowiskowy – rozwiązanie dla małych spawalni, pojedynczych stanowisk lub jako dodatkowe urządzenie mające za zadanie filtrować powietrze w miejscach, gdzie filtrowentylacja centralna nie oczyszcza powietrza na wymaganym poziomie.",
  "Filtrowentylacja oparta o filtr stacjonarny - instalacja odciągowa nawiewno-wywiewna, w celu odciągu i filtrowania gazów spawalniczych i pyłów szlifierskich. Ma za zadanie zapewnienie możliwości całkowitej lub częściowej wymiany oczyszczonego powietrza z powietrzem zewnętrznym. Centralne urządzenia zazwyczaj wyposażone są w funkcję automatycznego samooczyszczania, zapewniając recyrkulację powietrza po oczyszczeniu.",
  "Filtrowentylacja oparta o centralny system wymiany powietrza - istota działania systemu polega na wykorzystaniu zasady konwekcji ciepłego powietrza do góry. Czyste przefiltrowane powietrze z domieszką powietrza z zewnątrz jest nawiewane kolumnami nawiewnymi na dwa poziomy. Powietrze wtłaczane jest chłodniejsze od powietrza zawierającego pyły i gazy z procesów spawania. Różnica temperatur oraz ciśnienia na poziomie podłogi oraz sufitu tworzy strumień unoszący się do góry. Nawiewniki dolne rozprowadzają powietrze równomiernie na poziomie posadzki. Dodatkowo nawiewniki zamontowane na poziomie 2-3 metrów ukierunkowują i wzmacniają strumień przepływu w stronę instalacji wyciągowej. Tak ukształtowane widmo powietrza porywa zanieczyszczenia spawalnicze w kierunku stref ssania. Odciągnięte zanieczyszczone powietrze kierowane jest do zespołu filtracyjnego, gdzie jest oczyszczone i skierowane z powrotem do kolumn nawiewnych. Jednocześnie do instalacji nawiewnej podawane jest czyste powietrze z zewnątrz w odpowiednich proporcjach. Dostarczanie powietrza z zewnątrz jest istotnym elementem wentylacji zarówno zimą, jak i latem. Ilość powietrza z zewnątrz może być mniejsza zimą, a większa latem. Nigdy jednak ten udział nie powinien być mniejszy niż 15%. Liczba wymian powietrza jest uzależniona od intensywności prowadzonych prac i zawiera się w przedziale 3-7 wymian.",
];

export const weldingCompetences: ImageModalInfo[] = [
  {
    id: 1,
    title: "JAK OCENIĆ WŁASNE KOMPETENCJE?",
    image: {
      content: competencesImage1,
      size: "cover",
      position: "0% 25%",
    },
    items: [
      {
        content:
          "Obiektywna ocena kompetencji spawalniczych nie jest łatwym zadaniem. Jeśli jednak chcesz zweryfikować swoją wiedzę we własnym zakresie, przygotowaliśmy poniżej „pigułkę wiedzy”, która zawiera najważniejsze informacje z zakresu kompetencji spawalniczych.",
      },
    ],
  },
  {
    id: 2,
    title: "CZY KAŻDY KTO SPAWA JEST SPAWACZEM?",
    image: {
      content: competencesImage2,
      size: "cover",
      position: "100% 30%",
    },
    items: [
      {
        content:
          "Jak mówi definicja - spawaczem jest osoba fizycznie wykonująca prace spawalnicze, czyli mówiąc krótko - łączy elementy metalowe różnymi technikami spawania w sposób nierozerwalny. Może także wykonywać cięcie elementów z metalu bądź tworzyw sztucznych przy wykorzystaniu odpowiednich metod spawalniczych. Spawacz (czyli osoba spawająca) niekoniecznie musi być zatrudniony na stanowisku o nazwie „spawacz”. Często prace spawalnicze wykonuje np. konstruktor, mechanik, konserwator itp. Pamiętaj jednak, że niezależnie od nazwy stanowiska - aby móc spawać, niezbędne są odpowiednie kwalifikacje. I nie chodzi tylko o samą umiejętność łączenia materiałów, ale także o to, aby wiedzieć jak wykonywać czynności spawalnicze w sposób niezagrażający zdrowiu i życiu własnemu oraz osób, które znajdują się w naszym otoczeniu.",
      },
    ],
  },
  {
    id: 3,
    title: "SPAWACZ CZY SPAWALNIK?",
    image: {
      content: competencesImage3,
      size: "contain",
      position: "center",
    },
    items: [
      {
        content:
          "Mimo, że te dwa zawody brzmią podobnie, zasadniczo różnią się. Spawalnikiem jest osoba, która ma wykształcenie i wiedzę w zakresie technologii spawania. Jednak niekoniecznie musi mieć kwalifikacje spawacza i wykonywać tę pracę w praktyce. W takiej sytuacji spawalnik zajmuje się procesami spawania tylko w teorii.",
      },
    ],
  },
  {
    id: 4,
    title: "PODSTAWOWE KWALIFIKACJE SPAWACZA",
    image: {
      content: competencesImage4,
      size: "cover",
      position: "0% 100%",
    },
    items: [
      {
        content:
          "Należy pamiętać, że zgodnie z zaleceniami BHP, nie można spawać bez stosownych kwalifikacji zawodowych. Bez znaczenia czy spawamy w zakładzie pracy, czy w przydomowym garażu. Zawsze należy zdobyć przynajmniej minimalną wiedzę z zakresu techniki spawalnictwa oraz zasad BHP w procesie spawania.",
      },
      {
        content: "",
      },
      {
        content:
          "W zakładzie pracy – bez względu na to, czy pracownik jest zatrudniony na stanowisku spawacza czy jest np. „złotą rączką” i spawa raz na dwa tygodnie przez 10 minut – zawsze musi posiadać kwalifikacje do prowadzenia prac spawalniczych. Panuje przeświadczenie, że spawacz „z doskoku” kwalifikacji nie potrzebuje. Jest to nieprawda, ponieważ każdy kto spawa - musi mieć odpowiednie kwalifikacje.",
      },
      {
        content: "",
      },
      {
        content:
          "Kwalifikacje spawalnicze należy rozumieć jako ukończenie odpowiedniego przeszkolenia teoretycznego i praktycznego w zakresie spawalnictwa, potwierdzone egzaminem oraz dokumentem upoważniającym do wykonywania prac spawalniczych.",
      },
      {
        content:
          "Według rozporządzenia Ministra Gospodarki z dnia 27 kwietnia 2000 r. w sprawie bezpieczeństwa i higieny pracy przy pracach spawalniczych Dz.U. 2000 nr 40 poz. 470, spawać mogą osoby, które mają:",
        icon: IconType.check,
        items: [
          { title: "Zaświadczenie o ukończeniu szkolenia, albo" },
          { title: "Świadectwo egzaminu spawacza albo" },
          { title: "Książeczkę spawacza" },
        ],
      },
      {
        content:
          "Wymienione powyżej rodzaje kwalifikacji muszą być wydane w oparciu o odpowiednie przepisy i normy.",
      },
      {
        content: "",
      },
      {
        content:
          "Ponadto, osoby wykonujące ręczne cięcie termiczne, zgrzewanie, ręczne lutowanie oraz zmechanizowane i automatyczne wykonywanie prac spawalniczych - powinny posiadać przynajmniej zaświadczenie o ukończeniu szkolenia.",
      },
      {
        content: "",
      },
      {
        content:
          "Podobnie jak w przypadku uzyskiwania innych kompetencji, spawacz powinien ukończyć szkolenie, a następnie zdać egzamin i uzyskać świadectwo pozytywnie zdanego egzaminu spawacza. Często zdarza się, że jeśli spawacz wykonuje w zakładzie prace spawalnicze związane np. z naprawami, to wystarczy tylko jedna z powyżej wymienionych możliwych kwalifikacji. Praktyka pokazuje jednak, że spawacz musi w zasadzie spełnić wszystkie wymagania kwalifikacyjne jednocześnie jeśli wykonuje produkty spełniające określone normy. Wynika to z wymagań jakościowych dla produktów spawanych, czyli przyjętych do stosowania w zakładach norm i/ lub wymagań jakościowych dla spoin (a nie spawów). W takiej sytuacji to nie przepisy, a pracodawcy są bardziej wymagający w zakresie kwalifikacji swoich pracowników.",
      },
      {
        content:
          "Stosowanie Polskich Norm jest dobrowolne, ale jeśli są one wskazane w aktach prawnych, to ich stosowanie staje się obowiązkowe. I właśnie z takim przypadkiem mamy do czynienia w odniesieniu do kwalifikacji spawalniczych:",
        icon: IconType.check,
        items: [
          {
            title:
              "PN-EN ISO 9606-1:2017-10 Egzamin kwalifikacyjny spawaczy - Spawanie - Część 1: Stale",
          },
          {
            title:
              "PN-EN ISO 9606-2:2007 Egzamin kwalifikacyjny spawaczy - Spawanie - Część 2: Aluminium i stopy aluminium",
          },
          {
            title:
              "PN-EN ISO 9606-3:2001 Egzaminowanie spawaczy - Spawanie - Część 3: Miedź i stopy miedzi",
          },
          {
            title:
              "PN-EN ISO 9606-4:2001 Egzaminowanie spawaczy - Spawanie - Część 4: Nikiel i stopy niklu",
          },
          {
            title:
              "PN-EN ISO 9606-5:2002 Egzaminowanie spawaczy - Spawanie - Część 5: Tytan i stopy tytanu, cyrkon i stopy cyrkonu",
          },
        ],
      },
      {
        content: "",
      },
      {
        content:
          "Uprawnienia spawalnicze są nabywane podczas egzaminu przeprowadzanego w zależności od spawanego materiału. Kursy spawania oraz egzaminy prowadzone są według programów przez jednostki upoważnione przez Instytut Spawalnictwa, Urząd Dozoru Technicznego, Polski Rejestr Statków, TÜV Rheinland",
      },
    ],
  },
  {
    id: 5,
    title: "CZY KWALIFIKACJE SPAWALNICZE SĄ BEZTERMINOWE?",
    image: {
      content: competencesImage5,
      size: "cover",
      position: "0% 0%",
    },
    items: [
      {
        content:
          "Warto pamiętać, że uprawnienia spawalnicze nie są dożywotnie. Uprawnienia są ważne trzy lata (w przypadku stali) lub dwa lata (w przypadku aluminium, miedzi, niklu oraz tytanu). Istnieje oczywiście możliwość ich przedłużenia, pod warunkiem, że nadzór spawalniczy lub egzaminator potwierdzi, że spawacz pracował w zakresie swoich kwalifikacji w sposób ciągły (przerwy w spawaniu nie powinny być dłuższe niż 6 miesięcy).",
      },
    ],
  },
  {
    id: 6,
    title: "METODY SPAWANIA A UPRAWNIENIA SPAWACZA",
    image: {
      content: competencesImage6,
      size: "cover",
      position: "center",
    },
    items: [
      {
        content:
          "Spawacz może łączyć przedmioty metalowe (oraz wykonane z innych materiałów) różnymi metodami, do których posiada uprawnienia. Wykaz metod spawania określa norma: PN-EN ISO 4063:2011",
      },
      {
        content:
          "Spawanie i procesy pokrewne – Nazwy i numery procesów. Zgodnie z nią, wyróżnia się następujące metody spawania i ich oznaczenia:",
        icon: IconType.check,
        items: [
          { title: "spawanie plazmowe (15)" },
          { title: "spawanie łukowe elektrodą otuloną (111)" },
          {
            title:
              "spawanie drutem elektrodowym proszkowym samoosłonowym (114)",
          },
          { title: "spawanie łukiem krytym drutem elektrodowym litym (121)" },
          { title: "spawanie łukiem krytym elektrodą taśmową (122)" },
          { title: "spawanie łukiem krytym drutem proszkowym (125)" },
          {
            title: "spawanie metodą MIG (Metal Inert Gas)8 drutem litym (131)",
          },
          {
            title:
              "spawanie metodą MIG drutem proszkowym o rdzeniu topnikowym (132)",
          },
          { title: "spawanie metodą MIG drutem proszkowym metalicznym (133)" },
          {
            title:
              "spawanie metodą MAG (Metal Active Gas) drutem elektrodowym litym (135)",
          },
          {
            title:
              "spawanie metodą MAG drutem proszkowym o rdzeniu topnikowym (136)",
          },
          {
            title:
              "spawanie metodą MAG drutem proszkowym z rdzeniem metalicznym (138)",
          },
          {
            title:
              "spawanie metodą TIG (Tungsten Inert Gas) z dodatkiem drutu/pręta litego (141)",
          },
          { title: "spawanie metodą TIG bez dodatku stopiwa (142)" },
          {
            title:
            "spawanie metodą TIG z zastosowaniem drutu proszkowego (143)",
          },
          {
            title:
              "spawanie metodą TIG w osłonie gazu redukcyjnego z dodatkiem drutu/pręta litego (145)",
          },
          {
            title:
              "spawanie metodą TIG w osłonie gazu redukcyjnego z dodatkiem drutu proszkowego (146)",
          },
          { title: "spawanie metodą TIG w osłonie gazu aktywnego (TAG) (147)" },
          {
            title:
              "spawanie metodą TIG w osłonie gazu redukcyjnego z dodatkiem drutu/pręta litego (311)",
          },
        ],
      },
      {
        content: "",
      },
      {
        content:
          "W każdej z tych metod można uzyskać uprawnienia do spawania wyrobów z określonego rodzaju metalu (np. stali, aluminium i jego stopów, miedzi i jej stopów, niklu i jego stopów lub tytanu i jego stopów). Osoba pracująca w tym zawodzie jest odpowiedzialna za przygotowywanie elementów do spawania, realizację procesu spawania oraz utrzymanie właściwych parametrów tego procesu. Spawacz zajmuje się również wstępną oceną jakości wykonywanych spoin i usuwaniem niezgodności spawalniczych.",
      },
      {
        content: "",
      },
      {
        content: "DODATKOWE KWALIFIKACJE SPAWALNICZE",
        isBold: true,
      },
      {
        content:
          "Podstawowe kwalifikacje są najważniejsze, ale nigdy nie należy poprzestawać na raz zdobytych umiejętnościach, tylko nieustannie poszerzać i uaktualniać swoją wiedzę! Warto także rozwijać kompetencje przygotowujące do pracy w spawalnictwie zgodnie z międzynarodowymi standardami.",
      },
      {
        content:
          "Łukasiewicz - Instytut Spawalnictwa kwalifikuje personel spawalniczy według wymagań Międzynarodowego Instytutu Spawalnictwa (MIS) i Europejskiej Federacji Spawalniczej (EWF) wydając uznawane w krajach Unii Europejskiej i poza Europą dyplomy:",
        icon: IconType.check,
        items: [
          { title: "Międzynarodowy/Europejski Inżynier Spawalnik - IWE/EWE" },
          { title: "Międzynarodowy Inspektor Spawalniczy – IWI" },
          { title: "Międzynarodowy/Europejski Technolog Spawalnik - IWT/ EWT" },
          { title: "Międzynarodowy/Europejski Mistrz Spawalnik - IWS/EWS" },
          {
            title: "Międzynarodowy/Europejski Instruktor Spawalniczy - IWP/EWP",
          },
          { title: "Międzynarodowy Spawacz – IW" },
          {
            title:
              "Makroskopowe i mikroskopowe badania metalograficzne materiałów konstrukcyjnych",
          },
          { title: "Obróbka cieplna złączy spawanych" },
          {
            title:
              "Europejski Technik Klejenia (European Adhesive Bonder - EAB)",
          },
          {
            title:
              "Europejski Specjalista Klejenia (European Specjalist Bonder - EAS)",
          },
          {
            title:
              "Europejski Inżynier Klejenia (European Adhesive Engineer - EAE)",
          },
          {
            title:
              "Specjalista Nadzorujący Spawanie Prętów Zbrojeniowych (Reinforcing Bars)",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    title:
      "PROFESJONALNY SYMULATOR SPAWANIA SOLDAMATIC - ROZSZERZONA RZECZYWISTOŚĆ",
    image: {
      content: competencesImage7,
      size: "cover",
      position: "0% 0%",
    },
    items: [
      {
        content:
          "Chcesz zrobić krok ku nowoczesności i w ciekawy oraz praktyczny sposób sprawdzić się w roli spawacza?",
      },
      {
        content: "",
      },
      {
        content: "Nic prostszego! ",
      },
      {
        content: "",
      },
      {
        content:
          "Zapraszamy do zweryfikowania swoich kompetencji w siedzibie Tomsystem, za pomocą nowoczesnego symulatora spawania SOLDAMATIC! To innowacyjne urządzenie, oparte na wirtualnej rzeczywistości,  pozwala poczuć „na własnej skórze” jak wygląda cały proces spawania ręcznego. Co ważne - symulator jest całkowicie bezpieczny i nie generuje kosztów związanych ze stratami materiałów. Pomaga w wyrobieniu „pamięci mięśniowej, jak również w podjęciu decyzji w jakiej technice spawalniczej czujemy się najpewniej. System pokazuje także jak powinna wyglądać idealna spoina. Jednym zdaniem - w szybki i komfortowy sposób weryfikuje i podnosi umiejętności spawalnicze użytkownika. ",
      },
      {
        content: "",
      },
      {
        content:
          "Symulator spawania SOLDAMATIC to doskonałe narzędzie do szkolenia przyszłych wykwalifikowanych spawaczy w efektywny i zrównoważony sposób. Posiada unikalny system i program szkoleniowy oraz bogaty wybór technik i pozycji spawalniczych, dając możliwość analizy popełnianych błędów.",
      },
      {
        content: "",
      },
      {
        content: "Zapraszamy do Tomsystem!",
      },
      {
        content:
          "Aby umówić się na spotkanie - prosimy o wcześniejszy kontakt mailowy lub telefoniczny:",
      },
      {
        content: "tomsystem@tomsystem.pl ",
        link: "mailto:tomsystem@tomsystem.pl ",
      },
      {
        content: "tel.: +48 17 863 72 27 / +48 17 863 27 01",
      },
      {
        content: "www.tomsystem.pl ",
        link: "https://tomsystem.pl/ ",
      },
      {
        content: "",
      },
    ],
  },
];

export const personalProtectiveEquipment: ReaderInfo[] = [
  {
    content:
      "Pamiętaj, że w trakcie wykonywania prac spawalniczych jesteś narażony na działanie niebezpiecznych czynników, zagrażających Twojemu zdrowiu i życiu (m.in. dym i pył spawalniczy, iskry, promieniowanie, hałas).",
    isBold: true,
  },
  {
    content:
      "Zanim zaczniesz pracę – sprawdź czy posiadasz niezbędne środki ochrony osobistej, które posiadają stosowne atesty oraz spełniają wymogi odpowiednich norm i klas ochrony:",
    icon: IconType.check,
    items: [
      {
        title: "Ochrona twarzy i układu oddechowego (przyłbice, maski, tarcze)",
      },
      { title: "Ochrona głowy (kaptury)" },
      { title: "Ochrona oczu (okulary, gogle)" },
      { title: "Ochrona ciała (fartuchy, kombinezony)" },
      { title: "Ochrona rąk (rękawice, rękawy)" },
      {
        title:
          "Ochrona nóg/stóp (nakolanniki, nagolenniki, nastopniki, spodnie)",
      },
      { title: "Ochrona układu słuchowego (wkładki przeciwhałasowe do uszu)" },
    ],
  },
  {
    content:
      "Jeśli brakuje Ci czegoś z powyższej listy – dobierz odpowiedni asortyment w sklepie Tomsystem. Posiadamy szeroki wachlarz produktów, które zadbają o Twoje bezpieczeństwo. ",
  },
];

export const additionalQuestionsOfCut: ReaderInfo[] = [
  {
    content:
      "Aby uzyskać jak najlepsze efekty cięcia, warto szczegółowo określić cechy rozdzielanych materiałów, odpowiadając m.in. na poniższe pytania:",
  },
  {
    content: "Jaka jest grubość ciętego materiału?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content: "Jaka jest wielkość (powierzchnia) ciętego materiału?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content:
      "Jaka jest czystość ciętego materiału (materiał wyczyszczony, zardzewiały czy zanieczyszczony)?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content:
      "Nie masz pewności czy cięty materiał jest odpowiednio przygotowany do tego procesu?",
  },
  {
    content:
      "Zapraszamy do Tomsystem! Doradzimy czego potrzebujesz, aby proces cięcia materiałów przebiegł prawidłowo.",
  },
];

export const additionalQuestions: ReaderInfo[] = [
  {
    content:
      "Aby uzyskać jak najlepsze efekty spawania, warto szczegółowo określić cechy i właściwości łączonych materiałów, odpowiadając na poniższe pytania:",
  },
  {
    content: "Jaka jest grubość spawanego materiału?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content: "Jaka jest wielkość (powierzchnia) spawanego materiału?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content:
      "Jaka jest czystość spawanego materiału (materiał wyczyszczony, zardzewiały czy zanieczyszczony)?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content: "Czy będą łączone materiały różnoimienne?",
    isBold: true,
    icon: IconType.warning,
  },
  {
    content: "Pamiętaj, że:",
    icon: IconType.check,
    items: [
      {
        title:
          "W zależności od grubości łączonych elementów - ich brzegi muszą być przed spawaniem odpowiednio przygotowane",
      },
      {
        title:
          "Wartość natężenia prądu spawania dobierana jest w zależności od grubości spawanego materiału",
      },
      {
        title:
          "Pamiętaj, że materiał spawany powinien być pozbawiony wszelkich zanieczyszczeń oraz tłustych plam, gdyż w przeciwnym razie istnieje ryzyko pojawienia się odprysków wokół spoiny oraz spawy nie będą właściwe",
      },
      {
        title:
          "Warto zwrócić uwagę czy będą spawane materiały różnoimienne, ponieważ ich zróżnicowana przewodność cieplna może sprzyjać dodatkowemu odkształcaniu materiału",
      },
    ],
  },
  {
    content:
      "Nie masz pewności czy materiał spawany jest odpowiednio przygotowany do tego procesu? Potrzebujesz dobrać preparaty, które zagwarantują najlepsze efekty łączenia materiałów? ",
  },
  {
    content: "Zapraszamy do Tomsystem! ",
  },
  {
    content:
      "Na podstawie udzielonych odpowiedzi na temat spawanego materiału – doradzimy czego potrzebujesz, aby spawanie spełniło Twoje oczekiwania i zakończyło się sukcesem! ",
  },
];

export const gazInformation: ReaderInfo[] = [
  {
    content:
      "Większość metod spawania wymaga stosowania odpowiednich gazów technicznych.",
    isBold: true,
  },
  {
    content: "W jakim celu?",
    icon: IconType.warning,
    items: [
      {
        title:
          "Ochrona stopionego i rozgrzanego materiału przed wpływem powietrza atmosferycznego",
      },
      {
        title:
          "Stworzenie jak najlepszych warunków dla jarzenia się łuku elektrycznego",
      },
    ],
  },
  {
    content: "Najczęściej stosowane gazy osłonowe to:",
    icon: IconType.warning,
    items: [
      {
        title: "Argon (Ar)",
        description:
          "Argon stosuje się tam, gdzie materiał jest narażony na działanie Azotu lub Tlenu. Stanowi bazę do przygotowania mieszaniny gazów osłonowych.",
      },
      {
        title: "Dwutlenek węgla (CO2)",
        description:
          "Dwutlenek węgla bardzo dobrze chłodzi uchwyt spawalniczy i tym samym skutecznie chroni go przed przegrzaniem.",
      },
      {
        title: "Hel (He)",
        description:
          "Hel pozwala na większą prędkość spawania i umożliwia uzyskanie dobrej jakości spoiny.",
      },
      {
        title: "Wodór (H)",
        description:
          "Wodór to gaz aktywny, który zwiększa ciepło łuku oraz prędkość spawania. Dzięki niemu powstaje dobrej jakości spoina.",
      },
      {
        title: "Tlen (O2)",
        description:
          "Tlen nie jest gazem osłonowym, lecz wpływa na podniesienie temperatury spawania, a także na większą stabilność i czystość płomienia. Pełni funkcję dodatku do innych gazów.",
      },
    ],
  },
  {
    content: "",
  },
  {
    content:
      "W procesie spawania stosuje się także mieszanki o różnym składzie procentowym gazów.",
    isBold: true,
  },
  {
    content:
      "Dobór gazu lub mieszanki zależy od tego, którą metodę spawania chcemy zastosować.",
    isBold: true,
  },
];

export const bhpData: ReaderInfo[] = [
  {
    content: "GŁÓWNE ZAGROŻENIA ZWIĄZANE Z WYKONYWANIEM ZAWODU SPAWACZA",
    icon: IconType.dangerous,
    items: [
      { title: "Poparzenie gorącymi kawałkami metalu" },
      { title: "Promieniowanie ultrafioletowe" },
      { title: "Pożar i wybuch gazów spawalniczych" },
      { title: "Pyły i dymy spawalnicze" },
      { title: "Praca w wymuszonej pozycji" },
      { title: "Przenoszenie ciężkich narzędzi i elementów" },
      { title: "Narażenie na wysoki poziom hałas" },
    ],
  },
  {
    content: "JAK WYELIMINOWAĆ POWYŻSZE ZAGROŻENIA?",
    icon: IconType.check,
    items: [
      { title: "Stosuj specjalne okulary, przyłbice i tarcze chroniące oczy" },
      {
        title:
          "Stosuj środki ochrony układu oddechowego, odzież oraz rękawice ochronne",
      },
      {
        title:
          "Zainstaluj skuteczną wentylację/klimatyzację/filtrowentylację w miejscu pracy",
      },
      {
        title:
          "Stosuj bezpieczne metody podnoszenia i przenoszenia ciężkich lub nieporęcznych ładunków",
      },
      {
        title:
          "Stosuj urządzenia mechaniczne ułatwiające podnoszenie i przenoszenie ładunków",
      },
      {
        title:
          "Stosuj obuwie ochronne i maty podłogowe zapewniające komfort pracy w pozycji stojącej",
      },
      { title: "Stosuj przerwy w pracy na odpoczynek i ćwiczenia" },
      {
        title:
          "Sprawdzaj drabinę przed wejściem na nią, czy nie jest uszkodzona",
      },
      { title: "Stosuj ochronniki słuchu" },
      { title: "Sprawdzaj stan techniczny urządzeń elektrycznych przed pracą" },
      {
        title:
          "Zlecaj uprawnionemu pracownikowi naprawę i okresowy przegląd urządzeń",
      },
      {
        title:
          "Poddawaj systematycznym kontrolom butle gazowe oraz ich osprzęt",
      },
      {
        title:
          "Zwracaj szczególną uwagę na stan przewodów gumowych, zaworów i butli",
      },
    ],
  },
];

export const welderTypes: ImageBoxInfo[] = [
  {
    id: 1,
    title: "amatorskie",
    image: welder1,
  },
  {
    id: 2,
    title: "półprofesjonalne",
    image: welder2,
  },
  {
    id: 3,
    title: "profesjonalne",
    image: welder3,
  },
  {
    id: 4,
    title: "topowe",
    image: welder4,
  },
];

export const welderTypesOfCut: ImageBoxInfo[] = [
  {
    id: 1,
    title: "półprofesjonalne",
    image: welder2,
  },
  {
    id: 2,
    title: "profesjonalne",
    image: welder3,
  },
];

export const factsAndMyths: AccordionInfo[] = [
  {
    id: 1,
    title:
      "STOSOWANIE BUTLI GAZOWYCH JEST CAŁKOWICIE BEZPIECZNE I NIE STWARZA ZAGROŻENIA",
    answer: false,
    content:
      "Mimo, iż każdy może korzystać z butli z gazem bez konieczności legitymowania się wiedzą z zakresu BHP, to zawsze należy pamiętać o zagrożeniu jakie niesie ich stosowanie i bezwzględnie zachować ostrożność, ponieważ błąd w obsłudze butli gazowych może mieć katastrofalne skutki.",
  },
  {
    id: 2,
    title:
      "KOLOR BUTLI GAZOWEJ JEST JEDYNYM WIĄŻĄCYM OZNAKOWANIEM ZAWARTOŚCI BUTLI",
    answer: false,
    content:
      "Kolor butli gazowej wskazuje jej zawartość, lecz nie zastępuje nalepki substancji niebezpiecznej, która jest jedynym wiążącym oznakowaniem zawartości butli.",
  },
  {
    id: 3,
    title:
      "NIEPRAWIDŁOWO SKŁADOWANE BUTLE Z GAZEM MOGĄ STANOWIĆ ZNACZNE ZAGROŻENIE",
    answer: true,
    content:
      "Butle gazowe zawsze należy chronić przed silnym nagrzewaniem, ogniem, korozją, uszkodzeniem mechanicznym i bezprawnym dostępem.",
  },
  {
    id: 4,
    title:
      "USZKODZENIE PRZEWODÓW I WĘŻY PODŁĄCZONYCH DO BUTLI Z GAZEM NIE STWARZA ZAGROŻENIA",
    answer: false,
    content:
      "Przed przystąpieniem do użytkowania lub przed zmianą butli, należy zawsze sprawdzać przewody i węże czy nie wykazują uszkodzeń oraz upewniać się o szczelności wszystkich połączeń. Dla uniknięcia uszkodzeń węży, należy zwracać uwagę, aby nie ocierały się one o narożniki i krawędzie albo nie przecinały dróg komunikacyjnych, gdzie mogą być najeżdżane. Armatury, węże i narzędzia do tlenu muszą być zawsze wolne od smaru, oleju i zanieczyszczeń, bo inaczej zachodzi ryzyko samozapłonu.",
  },
  {
    id: 5,
    title: "NAPRAWĘ BUTLI GAZOWEJ MOŻE WYKONAĆ KAŻDA OSOBA",
    answer: false,
    content:
      "Naprawy butli, w tym naprawa zaworów, powinny być wykonywane wyłącznie przez osoby posiadające uprawnienia określone w odrębnych przepisach.",
  },
  {
    id: 6,
    title:
      "BUTLE Z TLENEM POWINNY BYĆ PRZECHOWYWANE W WYDZIELONYM POMIESZCZENIU",
    answer: true,
    content:
      "Nie powinno przechowywać się butli z tlenem razem z substancjami o właściwościach palnych, redukujących, żrących i toksycznych.",
  },
  {
    id: 7,
    title:
      "GAZY TECHNICZNE STOSUJE SIĘ W SPAWALNICTWIE W CELU OCHRONY STOPIONEGO MATERIAŁU PRZED CZYNNIKAMI ATMOSFERYCZNYMI",
    answer: true,
    content:
      "Gazy techniczne pełnią ważną rolę  podczas prac spawalniczych wykonywanych metodami MIG/MAG oraz TIG. Można je podzielić na dwa rodzaje gazów spawalniczych – palne oraz osłonowe, w zależności od ich zastosowania w procesie spawania.",
  },
  {
    id: 8,
    title:
      "REDUKTOR MAJĄCY ZA ZADANIE UTRZYMANIE ODPOWIEDNIEGO CIŚNIENIA GAZU, POWINIEN BYĆ ULOKOWANY TAK DALEKO UCHWYTU SPAWALNICZEGO, JAK TO TYLKO MOŻLIWE ",
    answer: false,
    content:
      "Reduktor gazowy powinien być ulokowany jak najbliżej uchwytu spawalniczego. Gdy reduktor znajduje się zbyt daleko, skutkiem mogą być zmienne warunki przepływu gazu.",
  },
  {
    id: 9,
    title:
      "ZBYT WYSOKIE LUB ZBYT NISKIE CIŚNIENIE GAZU MOŻE PROWADZIĆ DO DEFEKTÓW SPOINY",
    answer: true,
    content:
      "Stosowanie nieprawidłowych ustawień wartości ciśnienia gazu, powoduje problemy w procesie spawania. Zbyt wysokie ciśnienie gazu może skutkować niestabilnym łukiem spawalniczym, większą ilością odprysków spoiny i niekompletnym przetopem. I odwrotnie – niskie ciśnienie gazu może prowadzić do defektów spoiny, ponieważ jeziorko spawalnicze jest niedostatecznie chronione.",
  },
  {
    id: 10,
    title:
      "BEZ WZGLĘDU NA WYBRANĄ METODĘ SPAWANIA - ZAWSZE MOŻNA KORZYSTAĆ Z DOWOLNYCH GAZÓW TECHNICZNYCH",
    answer: false,
    content:
      "W zależności od techniki spawania (TIG lub MIG/MAG) - korzysta się z różnych rodzajów gazów technicznych lub ich mieszanek.",
  },
];

export const listsData: Record<ListsId, ChoiceList> = {
  list_1: {
    id: ListsId.list1,
    name: "Lista nr 1",
    items: [
      {
        id: `${ListsId.list1}/1`,
        title: "Stal niestopowa",
        description: "stal węglowa",
        tooltip: true,
      },
      {
        id: `${ListsId.list1}/2`,
        title: "Stal niskostopowa",
        description:
          "w której obok węgla występują w niewielkich ilościach inne dodatki stopowe",
        tooltip: true,
      },
      {
        id: `${ListsId.list1}/3`,
        title: "Stal wysokostopowa",
        description:
          "nierdzewna, odporna na korozję, w której oprócz węgla występują inne dodatki stopowe o zawartości od kilku do nawet kilkudziesięciu procent",
        tooltip: true,
      },
      {
        id: `${ListsId.list1}/4`,
        title: "Metale nieżelazne",
        description: "np. Aluminium, Nikiel, Miedź, Magnez i ich stopy",
        tooltip: true,
      },
      {
        id: `${ListsId.list1}/5`,
        title: "Metale reaktywne",
        description: "np. Tytan, Cynk, Niob, Hafn",
        tooltip: true,
      },
      {
        id: `${ListsId.list1}/6`,
        title: "Staliwo",
        description: "wieloskładnikowy stop żelaza z węglem",
        tooltip: true,
      },
      {
        id: `${ListsId.list1}/7`,
        title: "Żeliwo",
        description: "wysokowęglowy stop żelaza z węglem",
        tooltip: true,
      },
    ],
  },
  list_2: {
    id: ListsId.list2,
    name: "Lista nr 3 - gazy i ich mieszaniny",
    items: [
      {
        id: `${ListsId.list2}/1`,
        title: "Argon (Ar)",
        available: [
          `${ListsId.list3}/2`,
          `${ListsId.list3}/3`,
          `${ListsId.list3}/5`,
          `${ListsId.list3}/6`,
        ],
      },
      {
        id: `${ListsId.list2}/2`,
        title: "Hel (He)",
        available: [`${ListsId.list3}/3`, `${ListsId.list3}/5`],
      },
      {
        id: `${ListsId.list2}/3`,
        title: "mieszanina Argonu (Ar) i Helu (He)",
        available: [`${ListsId.list3}/3`, `${ListsId.list3}/6`],
      },
      {
        id: `${ListsId.list2}/4`,
        title: "dwutlenek węgla (CO2)",
        available: [`${ListsId.list3}/3`],
      },
      {
        id: `${ListsId.list2}/5`,
        title: "mieszanina dwutlenku węgla (CO2) i Argonu (Ar)",
        available: [`${ListsId.list3}/3`],
      },
      {
        id: `${ListsId.list2}/6`,
        title: "mieszanina Argonu (Ar) i Tlenu (O2)",
        available: [`${ListsId.list3}/3`],
      },
      {
        id: `${ListsId.list2}/7`,
        title: "mieszanina Argonu (Ar) i Wodoru (H)",
        available: [`${ListsId.list3}/6`],
      },
    ],
  },
  list_3: {
    id: ListsId.list3,
    name: "Lista nr 3 - metoda spawania",
    items: [
      {
        id: `${ListsId.list3}/1`,
        title: "MMA",
        description:
          "stapianie metali w miejscu ich łączenia za pośrednictwem łuku elektrycznego powstającego pomiędzy spawanym elementem a elektrodą otuloną",
        image: mmaMethod,
        available: [
          `${ListsId.list1}/1`,
          `${ListsId.list1}/2`,
          `${ListsId.list1}/3`,
          `${ListsId.list1}/4`,
          `${ListsId.list1}/6`,
          `${ListsId.list1}/7`,
        ],
      },
      {
        id: `${ListsId.list3}/2`,
        title: "TIG",
        image: tigMethod,
        description:
          "proces spawania łukowego elektrodą nietopliwą w osłonie gazu obojętnego",
        available: [
          `${ListsId.list1}/2`,
          `${ListsId.list1}/3`,
          `${ListsId.list1}/4`,
          `${ListsId.list1}/5`,
          `${ListsId.list1}/6`,
          `${ListsId.list1}/7`,
        ],
      },
      {
        id: `${ListsId.list3}/3`,
        title: "MIG/MAG",
        image: migmagMethod,
        description: "spawanie elektrodą topliwą w osłonie gazów obojętnych",
        available: [
          `${ListsId.list1}/1`,
          `${ListsId.list1}/2`,
          `${ListsId.list1}/3`,
          `${ListsId.list1}/4`,
          `${ListsId.list1}/5`,
          `${ListsId.list1}/6`,
          `${ListsId.list1}/7`,
        ],
      },
      {
        id: `${ListsId.list3}/4`,
        title: "Spawanie gazowe",
        image: gazMethod,
        description:
          "stapianie brzegów metali łączonych poprzez nagrzanie płomieniem spalającego się gazu palnego w atmosferze dostarczanego tlenu",
        available: [
          `${ListsId.list1}/1`,
          `${ListsId.list1}/2`,
          `${ListsId.list1}/3`,
          `${ListsId.list1}/4`,
        ],
      },
      {
        id: `${ListsId.list3}/5`,
        title: "Spawanie laserowe",
        available: [`${ListsId.list1}/3`],
        image: laserMethod,
        description: "stapianie obszaru styku wiązką promieni laserowych",
      },
      {
        id: `${ListsId.list3}/6`,
        title: "Spawanie plazmowe",
        available: [`${ListsId.list1}/3`],
        image: plazmMethod,
        description:
          "spawanie z wykorzystaniem ogniskowania łuku elektrycznego",
      },
    ],
  },
  list_4: {
    id: ListsId.list4,
    name: "Lista nr 4 - warunki spawania",
    items: [
      {
        id: `${ListsId.list4}/1`,
        title: "W pomieszczeniu z dopływem świeżego powietrza",
        description:
          "(uwaga: spawanie w warunkach bez wentylacji czyli dopływu świeżego powietrza – poważnie zagraża zdrowiu i życiu spawacza)",
      },
      {
        id: `${ListsId.list4}/2`,
        title: "W pomieszczeniu zamkniętym bez filtrowentylacji",
        description:
          "(uwaga: spawanie w pomieszczeniu zamkniętym w warunkach bez filtrowentylacji czyli systemu oczyszczania powietrza z pyłów, dymów i zapachów – poważnie zagraża zdrowiu i życiu spawacza. Sprawdź ofertę Tomsystem w zakresie filtrowentylacji)",
        linkText: "kontakt do TOMSYSTEM",
        linkUrl: "https://tomsystem.pl/kontakt/",
      },
      {
        id: `${ListsId.list4}/3`,
        title: "Na wolnym powietrzu",
        description:
          " (uwaga: nieskomplikowaną metodą przydatną do spawania na wolnym powietrzu jest spawanie MMA, czyli elektrodami otulonymi. Sprawdź ofertę Tomsystem w zakresie spawarek elektrodowych)",
        linkText: "kontakt do TOMSYSTEM",
        linkUrl: "https://tomsystem.pl/kontakt/",
      },
      {
        id: `${ListsId.list4}/4`,
        title: "W rurach/zbiornikach bez dostępu świeżego powietrza",
        description:
          "(uwaga: czasem sytuacja wymaga, aby spawacz pracował wewnątrz rurociągów lub zbiorników - pamiętaj wtedy o zastosowaniu odpowiednich środków bezpieczeństwa, m.in. maski i aparaty do oddychania czystym powietrzem, zasilane z butli tlenowej)",
      },
    ],
  },
  list_8: {
    id: ListsId.list8,
    name: "Lista nr 8. Wielkość butli",
    items: [
      {
        id: `${ListsId.list8}/1`,
        title: "mała (8 litrów lub 10 litrów lub 12 litrów)",
        available: [
          `${ListsId.list2}/1`,
          `${ListsId.list2}/2`,
          `${ListsId.list2}/3`,
          `${ListsId.list2}/5`,
          `${ListsId.list2}/6`,
          `${ListsId.list2}/7`,
        ],
      },
      {
        id: `${ListsId.list8}/2`,
        title: "średnia (20 litrów)",
        available: [
          `${ListsId.list2}/1`,
          `${ListsId.list2}/2`,
          `${ListsId.list2}/3`,
          `${ListsId.list2}/5`,
          `${ListsId.list2}/6`,
          `${ListsId.list2}/7`,
        ],
      },
      {
        id: `${ListsId.list8}/3`,
        title: "duża (40 litrów lub 50 litrów)",
        available: [
          `${ListsId.list2}/1`,
          `${ListsId.list2}/2`,
          `${ListsId.list2}/3`,
          `${ListsId.list2}/6`,
          `${ListsId.list2}/5`,
          `${ListsId.list2}/7`,
        ],
      },
      {
        id: `${ListsId.list8}/4`,
        title: "mała (6 kg)",
        available: [`${ListsId.list2}/4`],
      },
      {
        id: `${ListsId.list8}/5`,
        title: "duża (30 kg)",
        available: [`${ListsId.list2}/4`],
      },
    ],
  },
};

export const marksData: Record<MarksId, SliderMarks[]> = {
  mark_1: [
    {
      value: 1,
      label: "0.1 mm",
      thickness: "0,1 mm",
    },
    {
      value: 2,
      thickness: "4 mm",
    },
    {
      value: 3,
      thickness: "10 mm",
    },
    {
      value: 4,
      thickness: "25 mm",
    },
    {
      value: 5,
      thickness: "35 mm",
    },
    {
      value: 6,
      thickness: "40 mm",
    },
    {
      value: 7,
      thickness: "50 mm",
    },
    {
      value: 8,
      thickness: ">60 mm",
      label: ">60 mm",
    },
  ],
  mark_2: [
    {
      value: 1,
      label: "0.1 mm",
      thickness: "0,1 mm",
    },
    {
      value: 2,
      thickness: "1 mm",
    },
    {
      value: 3,
      thickness: "2 mm",
    },
    {
      value: 4,
      thickness: "3 mm",
    },
    {
      value: 5,
      thickness: "5 mm",
    },
    {
      value: 6,
      thickness: "7 mm",
    },
    {
      value: 7,
      thickness: "> 10 mm",
      label: ">10mm",
    },
  ],
  mark_3: [
    {
      value: 1,
      hours: "do 1 godziny/miesiąc",
    },
    {
      value: 2,
      hours: "powyżej 1 godziny/miesiąc",
    },
    {
      value: 3,
      hours: "powyżej 1 godziny/dzień",
    },
    {
      value: 4,
      hours: "minimum 3-4 godziny/dzień",
    },
  ],
  mark_4: [
    {
      value: 1,
      hours: "powyżej 1 godziny/miesiąc",
    },
    {
      value: 2,
      hours: "powyżej 1 godziny/dzień",
    },
  ],
};

export const answersGazTemplate: AnswerResult[] = [
  {
    stepId: 31,
    question: "Rodzaj materiału:",
  },
  {
    stepId: 33,
    question: "Metoda spawania lub cięcia:",
  },
  {
    stepId: 35,
    question: "Wybrany gaz lub mieszanina:",
  },
  {
    stepId: 36,
    question: "Miejsce przechowywania:",
  },
  {
    stepId: 37,
    question: "Pojemność butli:",
  },
];

export const answersTemplate: AnswerResult[] = [
  {
    stepId: 2,
    question: "Warunki pracy:",
    path: AnswerPath.common,
  },
  {
    stepId: 13,
    question: "Przeznaczenie sprzętu:",
    path: AnswerPath.cut,
  },
  {
    stepId: 5,
    question: "Cięty materiał:",
    path: AnswerPath.cut,
  },
  {
    stepId: 7,
    question: "Grubość ciętego materiału:",
    path: AnswerPath.cut,
  },
  {
    stepId: 12,
    question: "Przeznaczenie sprzętu:",
    path: AnswerPath.weld,
  },
  {
    stepId: 8,
    question: "Spawany materiał:",
    path: AnswerPath.weld,
  },
  {
    stepId: 10,
    question: "Grubość spawanego materiału:",
    path: AnswerPath.weld,
  },
  {
    stepId: 11,
    question: "Technika spawania:",
    path: AnswerPath.weld,
  },
];

export const mySteps: Record<number, GenericStep> = {
  0: {
    id: 0,
    title: "Wprowadzenie do kreatora",
    component: Introduction,
    defaultTransition: 1,
    isOther: true,
    nextButton: "Dalej",
  },
  1: {
    id: 1,
    title: "Dobierz technologię",
    component: ButtonsSelect,
    componentProps: {
      buttonsOptions: pathChoices,
    },
    mainImage: {
      image: mainImageSelectWay,
    },
  },
  2: {
    id: 2,
    title: "W jakich warunkach będzie odbywało się spawanie?",
    defaultTransition: 3,
    component: OptionsList,
    componentProps: {
      listId: ListsId.list4,
    },
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  3: {
    id: 3,
    title: "BHP",
    defaultTransition: 4,
    component: ContentReader,
    componentProps: {
      data: bhpData,
      knowledgeBaseLink: "https://majsterkowanie-spawanie.pl/blog/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
      isShop: false,
    },
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
  },
  4: {
    id: 4,
    title: "Chcesz ciąć czy spawać?",
    component: ButtonsSelect,
    componentProps: {
      buttonsOptions: wayChoices,
    },
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
  },
  5: {
    id: 5,
    title: "Jaki materiał chcesz ciąć?",
    component: OptionsList,
    componentProps: {
      listId: ListsId.list1,
      knowledgeBaseLink:
        "https://technologie-spawania.pl/rodzaje-materialow-cietych-oraz-spawanych/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
    },
    defaultTransition: 6,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  6: {
    id: 6,
    title: "Dodatkowe pytania dotyczące ciętego materiału",
    defaultTransition: 7,
    component: ContentReader,
    componentProps: {
      data: additionalQuestionsOfCut,
    },
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
  },
  7: {
    id: 7,
    title: "Jaka jest grubość ciętego materiału?",
    component: MaterialThickness,
    componentProps: {
      marks: marksData.mark_1,
      type: MarksId.mark1,
      image: cuttingMaterial,
      knowledgeBaseLink:
        "https://technologie-spawania.pl/dopasowanie-natezenia-pradu-do-grubosci-cietego-materialu/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
    },
    defaultTransition: 13,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  8: {
    id: 8,
    title: "Jaki materiał chcesz spawać?",
    component: OptionsList,
    componentProps: {
      listId: ListsId.list1,
      knowledgeBaseLink:
        "https://technologie-spawania.pl/rodzaje-materialow-cietych-oraz-spawanych/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
    },
    defaultTransition: 9,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  9: {
    id: 9,
    title: "Dodatkowe pytania dotyczące spawanego materiału",
    component: ContentReader,
    componentProps: {
      data: additionalQuestions,
    },
    defaultTransition: 10,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
  },
  10: {
    id: 10,
    title: "Jaka grubość spawanego materiału?",
    component: MaterialThickness,
    componentProps: {
      marks: marksData.mark_2,
      type: MarksId.mark2,
      image: weldedMaterial,
      knowledgeBaseLink: "https://technologie-spawania.pl/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
    },
    defaultTransition: 11,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  11: {
    id: 11,
    title: "Jaka jest technika spawania?",
    component: ImagesList,
    componentProps: {
      listId: ListsId.list3,
      knowledgeBaseLink:
        " https://technologie-spawania.pl/przeglad-podstawowych-metod-spawania/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
      storeItem: 8,
    },
    defaultTransition: 12,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  12: {
    id: 12,
    title: "Jakie jest przeznaczenie sprzętu?",
    component: HoursSlider,
    componentProps: {
      marks: marksData.mark_3,
      welders: welderTypes,
      marksType: MarksId.mark3
    },
    defaultTransition: 14,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  13: {
    id: 13,
    title: "Jakie jest przeznaczenie sprzętu?",
    component: HoursSlider,
    componentProps: {
      marks: marksData.mark_4,
      welders: welderTypesOfCut,
      marksType: MarksId.mark4
    },
    defaultTransition: 14,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  14: {
    id: 14,
    title: "Pamiętaj o niezbędnych środkach ochrony osobistej",
    component: ImageReader,
    componentProps: {
      image: personalEquipment,
    },
    defaultTransition: 15,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dowiedz się",
    otherButton: { target: 16, label: "Pomiń" },
  },
  15: {
    id: 15,
    title: "Środki ochrony osobistej",
    component: ContentReader,
    componentProps: {
      data: personalProtectiveEquipment,
    },
    defaultTransition: 16,
    mainImage: {
      title: "technologia",
      image: mainImageTechnologyPath,
    },
    nextButton: "Dalej",
  },
  16: {
    id: 16,
    title: "Podsumowanie wyboru technologii",
    component: TechnologySummary,
    componentProps: {
      answers: answersTemplate,
      marks: marksData,
      lists: listsData,
    },
    defaultTransition: 1,
    isOther: true,
    nextButton: "Dobierz inne technologie",
  },
  20: {
    id: 20,
    title: "Kompetencje spawalnicze",
    component: WeldingCompetences,
    componentProps: {
      data: weldingCompetences,
    },
    mainImage: {
      title: "Kompetencje spawalnicze",
      image: mainImageCompetences,
    },
  },
  21: {
    id: 21,
    title: "Pozycje spawalnicze",
    component: ImageReader,
    componentProps: {
      image: weldingPositionsBackground,
      knowledgeBaseLink:
        "https://technologie-spawania.pl/przeglad-pozycji-spawania/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej na temat pozycji spawalniczych? Zapraszamy do naszej BAZY WIEDZY",
    },
    mainImage: {
      title: "Pozycje spawalnicze",
      image: mainImagePositions,
    },
  },
  30: {
    id: 30,
    title: "Informacja o rodzajach gazu i ich wykorzystaniu",
    component: ContentReader,
    componentProps: {
      data: gazInformation,
      knowledgeBaseLink: "https://tomsystemgt.com/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
      isShop: false,
    },
    defaultTransition: 31,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Dalej",
  },
  31: {
    id: 31,
    title: "Jaki materiał chcesz spawać?",
    component: OptionsList,
    componentProps: {
      listId: ListsId.list1,
      knowledgeBaseLink:
        "https://technologie-spawania.pl/rodzaje-materialow-cietych-oraz-spawanych/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
    },
    defaultTransition: 32,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  32: {
    id: 32,
    title: "Dodatkowe pytania dotyczące spawanego materiału",
    component: ContentReader,
    componentProps: {
      data: additionalQuestions,
    },
    defaultTransition: 33,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Dalej",
  },
  33: {
    id: 33,
    title: "Wybierz metodę spawania lub cięcia",
    component: ImagesList,
    componentProps: {
      listId: ListsId.list3,
      knowledgeBaseLink: "https://technologie-spawania.pl/blog/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
      storeItem: 31,
    },
    defaultTransition: 34,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  34: {
    id: 34,
    title: "Gazy techniczne - fakty i mity",
    component: AccordionPlatform,
    componentProps: {
      data: factsAndMyths,
      knowledgeBaseLink: "https://tomsystemgt.com/",
      knowledgeBaseTitle:
        "Chesz dowiedzieć się więcej? Zapraszamy do naszej BAZY WIEDZY",
    },
    defaultTransition: 35,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Pomiń",
  },
  35: {
    id: 35,
    title: "Wybierz gaz lub mieszaninę",
    component: OptionsList,
    componentProps: {
      listId: ListsId.list2,
      storeItem: 33,
    },
    defaultTransition: 36,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  36: {
    id: 36,
    title: "Gaz z butli czy ze zbiornika?",
    component: ButtonsSelect,
    componentProps: {
      buttonsOptions: cylinderChoices,
    },
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
  },
  37: {
    id: 37,
    title: "Jaką wielkość butli wybierasz?",
    component: OptionsList,
    componentProps: {
      listId: ListsId.list8,
      storeItem: 35,
    },
    defaultTransition: 38,
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    nextButton: "Dalej",
    requiredAnswer: true,
  },
  38: {
    id: 38,
    title: "Podsumowanie wyboru gazu",
    component: GazSummary,
    componentProps: {
      answers: answersGazTemplate,
      lists: listsData,
    },
    mainImage: {
      title: "gaz",
      image: mainImageGazPath,
    },
    defaultTransition: 1,
    isOther: true,
    nextButton: "Dobierz inne technologie",
  },
};

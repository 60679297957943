import {
  createAsyncThunk,
  createReducer,
  createSelector,
  createAction,
} from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { ErrorType, UserInfo } from "types";
import { RootState } from "redux/store";

export const addResults = createAsyncThunk(
  "post/results",
  async (results: any) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const res: AxiosResponse = await axios.post("/api/data", results, {
      headers: {
        Content_Type: "application/json",
      },
    });
    if (res.data) {
      return res.data;
    }
  }
);

export const addUserData = createAsyncThunk(
  "post/userData",
  async (data: { userData: UserInfo; pdf: File }) => {
    const formData = new FormData();
    const { userData, pdf } = data;
    formData.append("name", userData.name !== undefined ? userData.name : "");
    formData.append("email", userData.email);
    formData.append("phone", userData.phone);
    formData.append("document", pdf);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const res: AxiosResponse = await axios.post("/api/user", formData, {
      headers: {
        Content_Type: "application/json",
      },
    });
    if (res.data) {
      return res.data.message;
    }
  }
);

export const addResultFile = createAsyncThunk(
  "post/reportFile",
  async (report: File) => {
    const formData = new FormData();
    formData.append("document", report);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const res: AxiosResponse = await axios.post("/api/report", formData, {
      headers: {
        Content_Type: "application/json",
      },
    });
    if (res.data) {
      return res.data.message;
    }
  }
);

export const setMessage = createAction<string>("set/message");
export const resetErrors = createAction("reset/errors");

type ErrorInfo = {
  isError: boolean;
  type: ErrorType;
  message: string;
};

export type ResultsState = {
  results: any[];
  isPending: boolean;
  error: ErrorInfo;
  message: string;
  isSending: boolean;
  sendingError: ErrorInfo;
};

const initialState: ResultsState = {
  results: [],
  isPending: false,
  error: {
    isError: false,
    type: ErrorType.error,
    message: "Coś poszło nie tak.",
  },
  message: "",
  isSending: false,
  sendingError: {
    isError: false,
    type: ErrorType.error,
    message: "Coś poszło nie tak.",
  },
};

export const resultsApiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetErrors, (state) => {
      state.sendingError = { ...state.sendingError, isError: false };
      state.error = { ...state.error, isError: false };
    })
    .addCase(setMessage, (state, action) => {
      state.message = action.payload;
    })
    .addCase(addUserData.pending, (state) => {
      state.isSending = true;
      state.message = "";
      state.sendingError = { ...state.sendingError, isError: false };
    })
    .addCase(addUserData.fulfilled, (state, { payload }) => {
      state.isSending = false;
      if (payload) {
        state.message = payload;
      } else {
        state.sendingError = {
          isError: true,
          type: ErrorType.info,
          message: "Wiadomość nie została wysłana",
        };
      }
    })
    .addCase(addUserData.rejected, (state) => {
      state.sendingError = { ...state.sendingError, isError: true };
      state.isSending = false;
    })
    .addCase(addResultFile.pending, (state) => {
      state.isSending = true;
      state.message = "";
      state.sendingError = { ...state.sendingError, isError: false };
    })
    .addCase(addResultFile.fulfilled, (state, { payload }) => {
      state.isSending = false;
      if (payload) {
        state.message = payload;
      } else {
        state.sendingError = {
          isError: true,
          type: ErrorType.info,
          message: "Raport nie został przesłany do sklepu",
        };
      }
    })
    .addCase(addResultFile.rejected, (state) => {
      state.sendingError = { ...state.sendingError, isError: true };
      state.isSending = false;
    })
    .addCase(addResults.pending, (state) => {
      state.isPending = true;
      state.error = { ...state.error, isError: false };
    })
    .addCase(addResults.fulfilled, (state, { payload }) => {
      state.isPending = false;
      state.results = payload ? payload : [];
      if (!payload.length) {
        state.error = {
          isError: true,
          type: ErrorType.info,
          message: "Nie znaleziono urządzeń dla podanych kryteriów.",
        };
      }
    })
    .addCase(addResults.rejected, (state) => {
      state.error = { ...state.error, isError: true };
      state.isPending = false;
    });
});

export const selectApiResult = (state: RootState) => state.api;
export const apiSelector = createSelector(selectApiResult, (state) => state);

export default resultsApiReducer;

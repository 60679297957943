import React from "react";
import MainPage from "components/pages/MainPage";
import "./App.css";

const App = (): JSX.Element => {
  return (
    <div>
      <MainPage />
    </div>
  );
};

export default App;

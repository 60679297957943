import { StyleSheet, Font } from "@react-pdf/renderer";
import font from "fonts/Roboto-Medium.ttf";

Font.register({
  family: "Roboto-Medium",
  src: font,
});

export const styles = StyleSheet.create({
  page: {
    padding: "20px 0",
    flexDirection: "column",
    backgroundColor: "#FFF",
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
  },
  logo: {
    width: 120,
  },
  header: {
    fontSize: 12,
    textAlign: "right",
    fontFamily: "Roboto-Medium",
  },
  contact: {
    width: "100%",
  },
  content: {
    margin: 10,
    padding: 10,
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  textCenter: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Roboto-Medium",
    paddingTop: 30,
    paddingBottom: 10,
  },
  description: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Roboto-Medium",
    width: "60%",
  },
  result: {
    fontSize: 10,
    fontFamily: "Roboto-Medium",
    color: "#005BA2",
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  item: {
    fontFamily: "Roboto-Medium",
    fontSize: 10,
    textAlign: "left",
    width: "100%",
    paddingBottom: 5,
    paddingRight: 10,
  },
  text: {
    fontFamily: "Roboto-Medium",
    fontSize: 8,
  },
  resultsBox: {
    width: "100%",
  },
  imageBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 150,
    paddingBottom: 20,
  },
});

import React, { useState, forwardRef } from "react";
import {
  Grid,
  ImageList,
  ImageListItem,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Typography,
  Fade,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  Check,
  Dangerous,
  EmojiObjects,
  HelpOutlineOutlined,
  Close,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import classNames from "classnames";
import { TransitionProps } from "@mui/material/transitions";
import {
  CustomStepProps,
  ImageModalInfo,
  IconType,
  ReaderInfo,
  ReaderItemInfo,
} from "types";
import { isEven, CustomTooltip } from "functions";
import { useStyles } from "components/features/WeldingCompetences/WeldingCompetencesStyle";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const WeldingCompetences: React.FC<CustomStepProps> = ({
  data: genericData,
}): JSX.Element => {
  const classes = useStyles();
  const data = genericData as ImageModalInfo[];
  const theme = useTheme();
  const fullScrean = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    ImageModalInfo | undefined
  >();

  const onClick = (id: number) => {
    const modalContent = data.find((item: ImageModalInfo) => item.id === id);
    if (modalContent) {
      setSelectedItem(modalContent);
      setTimeout(() => setIsOpen(true), 200);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const iconContent = (type: IconType) => {
    switch (type) {
      case IconType.dangerous:
        return (
          <Dangerous
            className={classes.paddingRight}
            color="error"
            fontSize="small"
          />
        );
      case IconType.check:
        return (
          <Check
            className={classes.paddingRight}
            color="success"
            fontSize="small"
          />
        );
      case IconType.warning:
        return (
          <EmojiObjects
            className={classes.paddingRight}
            color="warning"
            fontSize="small"
          />
        );
      default:
        return (
          <Check
            className={classes.paddingRight}
            color="success"
            fontSize="small"
          />
        );
    }
  };

  const dataContent = (
    { content, isBold, icon, items, link }: ReaderInfo,
    value: number
  ) => {
    const contentClasses = classNames({
      [classes.text]: true,
      [classes.bold]: isBold,
    });
    if (!items && !link) {
      if (icon !== undefined) {
        return (
          <div key={`text${value}icon`} className={classes.rowCenter}>
            {iconContent(icon)}
            <Typography component="span" className={contentClasses}>
              {content}
            </Typography>
          </div>
        );
      } else {
        if (content.length > 0) {
          return (
            <Typography key={`text${value}`} className={contentClasses}>
              {content}
            </Typography>
          );
        } else {
          return <br key={`space${value}`} />;
        }
      }
    }
    if (items !== undefined && icon !== undefined) {
      return (
        <Table key={`first${value}`} className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.titleCell}>{content}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: ReaderItemInfo, index: number) => {
              return (
                <TableRow key={`second${index}`}>
                  <TableCell className={classes.cell}>
                    {iconContent(icon)}
                    {item.title}
                    {item.description && (
                      <CustomTooltip
                        title={item.description}
                        TransitionComponent={Fade}
                        arrow
                        placement="right"
                        TransitionProps={{ timeout: 600 }}
                      >
                        <HelpOutlineOutlined className={classes.helpIcon} />
                      </CustomTooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    if (link !== undefined) {
      return (
        <Link key={`link${value}`} href={link} target="_blank">
          {content}
        </Link>
      );
    }
  };

  return (
    <Grid justifyContent="center" display="flex">
      <Grid item xs={12} sm={12} md={10} className={classes.root}>
        <ImageList
          className={classes.imagesList}
          cols={1}
          rowHeight={330}
          variant="quilted"
        >
          {data.map((item: ImageModalInfo) => {
            return (
              <ImageListItem
                key={item.id}
                className={classNames(
                  classes.imageListItem,
                  isEven(item.id)
                    ? classes.imageListItemRight
                    : classes.imageListItemLeft
                )}
              >
                <ButtonBase
                  focusRipple
                  onClick={() => onClick(item.id)}
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                >
                  <span
                    className={classes.imageSrc}
                    style={{
                      backgroundImage: `url(${item.image.content})`,
                      backgroundSize: item.image.size,
                      backgroundPosition: item.image.position,
                    }}
                  />
                </ButtonBase>
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
      {selectedItem && (
        <Dialog
          fullScreen={fullScrean}
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{selectedItem.title}</DialogTitle>
          <div className={classes.closeButton}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <DialogContent>
            {selectedItem.items.map((data: ReaderInfo, value: number) => {
              return dataContent(data, value);
            })}
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
};

export default WeldingCompetences;

import { makeStyles, createStyles } from "@mui/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Theme, styled } from "@mui/material/styles";
import image from "images/selectWay3.jpeg";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "@media (min-width: 500px)": {
        overflowY: "auto",
      },
      "@media (min-width: 900px)": {
        overflowY: "unset",
      },
      height: "calc(100vh - 160px)",
      padding: "0 10px",
      backgroundColor: theme.palette.secondary.main,
      alignContent: "flex-start",
      pandingTop: 20,
    },
    main: {
      height: "calc(100vh - 160px)",
      "@media (min-width: 500px)": {
        alignContent: "flex-start",
        paddingTop: 50,
      },
      "@media (min-width: 900px)": {
        alignContent: "center",
        paddingTop: "unset",
      },
    },
    back: {
      height: "calc(100vh - 80px)",
      background: `url(${image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      mixBlendMode: "normal",
      backgroundColor: theme.palette.secondary.main,
    },
    header: {
      position: "relative",
      paddingBottom: 10,
      "&.MuiGrid-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    content: {
      padding: "0 2%",
      "&.MuiGrid-root": {
        flexDirection: "column",
      },
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    info: {
      padding: "20px 20px 10px",
      position: "relative",
      "&.MuiPaper-root": {
        backgroundColor: "rgba(255, 255, 255, .8)",
      },
    },
    bold: {
      "&.MuiTypography-root": {
        fontWeight: 500,
        fontSize: "0.9rem",
      },
    },
    carousel: {
      padding: 20,
      maxWidth: 600,
      width: "100%",
      height: "100%",
    },
    spinerBox: {
      height: 680,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    badge: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: -40,
      right: -45,
      width: 80,
      height: 80,
      borderRadius: "50%",
      backgroundColor: theme.palette.info.main,
      border: "2px solid #fff",
    },
    badgeContent: {
      "&.MuiBadge-root .MuiBadge-badge": {
        fontSize: "1rem",
      },
    },
    divider: {
      paddingBottom: 5,
    },
    button: {
      "&.MuiButtonBase-root": {
        backgroundColor: theme.palette.info.main,
        "&:hover": {
          backgroundColor: theme.palette.error.main,
        },
        "&:hover .MuiSvgIcon-root": { color: "#fff" },
      },
    },
    buttonContent: {
      padding: "0 10px",
    },
    dialogTitle: {
      color: theme.palette.info.main,
      textAlign: "center",
    },
    dialogContent: {
      display: "inline-flex",
      alignItems: "center",
      paddingBottom: 20,
      textAlign: "justify",
      "&.MuiTypography-root": {
        color: theme.palette.info.main,
      },
    },
    icon: {
      paddingRight: 10,
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  })
);

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

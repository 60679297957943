import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "@media (min-width: 500px)": {
        width: 120,
        height: 190,
      },
      "@media (min-width: 992px)": {
        width: 150,
        height: 210,
      },
      "@media (min-width: 1200px)": {
        width: 180,
        height: 240,
      },
      "@media (min-width: 1300px)": {
        width: 190,
        height: 240,
      },
      "@media (min-width: 1400px)": {
        width: 200,
        height: 250,
      },
    },
    selected: {
      transform: "translateY(0) !important",
    },
    image: {
      "@media (min-width: 500px)": {
        transform: "translateY(-166px)",
        width: 110,
        height: 180,
      },
      "@media (min-width: 992px)": {
        transform: "translateY(-186px)",
        width: 130,
        height: 200,
      },
      "@media (min-width: 1200px)": {
        transform: "translateY(-226px)",
        width: 160,
        height: 240,
      },
      "@media (min-width: 1300px)": {
        width: 170,
        height: 240,
      },
      "@media (min-width: 1400px)": {
        width: 190,
        height: 240,
      },
      margin: 10,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "flex-end",
      transition: "1s",
    },
    title: {
      backgroundColor: theme.palette.info.main,
      color: "#fff",
      width: "100%",
    },
  })
);

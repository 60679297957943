import React from "react";
import { Grid, TextField, Typography, Paper } from "@mui/material";
import classNames from "classnames";
import { CustomStepProps } from "types";
import { useStyles } from "components/features/Itroduction/IntroductionStyle";

type Result = {
  userName?: string;
};

const emptyResult: Result = {
  userName: "",
};

const Introduction: React.FC<CustomStepProps> = ({
  result,
  setResult,
}): JSX.Element => {
  const classes = useStyles();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result: Result = {
      userName: e.target.value,
    };
    setResult(result);
  };

  const { userName } = (result as Result) || emptyResult;

  return (
    <div>
      <Grid container className={classes.root}>
        <div className={classes.content}></div>
        <Grid className={classes.box} container>
          <Grid item xs={12} sm={12} md={4} className={classes.center}>
            <div className={classes.description}>
              <Typography className={classes.text} align="center" color="error">
                ROZWIĄŻ SWÓJ PROBLEM
              </Typography>
              <div>
                <Typography
                  className={classNames(classes.text, classes.otherText)}
                  align="center"
                >
                  UZYSKAJ POMOC
                </Typography>
                <Typography
                  className={classNames(classes.text, classes.otherText)}
                  align="center"
                >
                  W DOBORZE TECHNOLOGII
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={classes.center}>
            <Paper className={classes.inputBox} elevation={3}>
              <TextField
                className={classes.input}
                label="Imię"
                placeholder="Podaj imię"
                onChange={onChange}
                value={userName}
                color="error"
                focused
                size="small"
              >
                Podaj imię
              </TextField>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Introduction;

import React, { useEffect } from "react";
import { Grid, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CustomStepProps,
  SliderMarks,
  ImageBoxInfo,
  MarkSelection,
} from "types";
import ImageBox from "components/common/ImageBox/ImageBox";
import { useStyles } from "components/features/HoursSlider/HoursSliderStyle";

type Result = {
  userSelection: MarkSelection;
};


const HoursSlider: React.FC<CustomStepProps> = ({
  setResult,
  result,
  marks: genericMarks,
  welders: genericWelders,
  marksType
}): JSX.Element => {
  const emptyResult: Result = {
    userSelection: {
      value: 2,
      type: marksType,
    },
  };

  const classes = useStyles();
  const marks = genericMarks as SliderMarks[];
  const welders = genericWelders as ImageBoxInfo[];
  const { userSelection } = (result as Result) || emptyResult;

  useEffect(() => {
    if (result === undefined) {
      setResult(emptyResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.info.main,
    height: 8,
    "@media (min-width: 500px)": {
      width: marks.length > 2 ? 350 : 175,
    },
    "@media (min-width: 992px)": {
      width: marks.length > 2 ? 450 : 225,
    },
    "@media (min-width: 1200px)": {
      width: marks.length > 2 ? 550 : 275,
    },
    "@media (min-width: 1400px)": {
      width: marks.length > 2 ? 700 : 350,
    },
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 28,
      width: 28,
      backgroundColor: "#fff",
      border: `4px solid ${theme.palette.info.main}`,
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.info.main,
      height: 15,
      width: 15,
      borderRadius: "50%",
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: theme.palette.info.main,
    },
  }));

  const onChange = (event: Event, newValue: number | number[]) => {
    const result: Result = {
      userSelection: { ...emptyResult.userSelection, value: newValue },
    };
    setResult(result);
  };

  const valueText = (value: number) => {
    const resultMark = marks.find((item: SliderMarks) => item.value === value);
    if (resultMark) {
      return resultMark.hours;
    }
  };

  return (
    <Grid container justifyContent="center" className={classes.main}>
      <Grid item xs={12} sm={12} md={10} className={classes.slider}>
        <div className={classes.slider}>
          <CustomSlider
            step={1}
            marks={marks}
            value={userSelection.value}
            onChange={onChange}
            min={1}
            max={marks.length}
            valueLabelDisplay="on"
            valueLabelFormat={valueText}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={10} className={classes.center}>
        <div className={classes.imagesContent}>
          {welders.map((item: ImageBoxInfo) => {
            const { id, title, image } = item;
            return (
              <ImageBox
                key={id}
                id={id}
                title={title}
                selectedId={
                  result === undefined
                    ? emptyResult.userSelection.value
                    : result.userSelection.value
                }
                image={image}
              />
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default HoursSlider;

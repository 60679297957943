import React from "react";
import { Grid } from "@mui/material";
import StepEditPlatform from "components/features/StepEditPlatform/StepEditPlatform";
import { useStyles } from "components/pages/MainPageStyle";
import logo from "images/Logo.png";

const MainPage: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className={classes.header}>
            <Grid item xs={12} sm={12} md={3} className={classes.logoBox}>
              <img src={logo} alt="logo" />
            </Grid>
          </Grid>
          <StepEditPlatform />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainPage;
